var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"home-page-container",staticStyle:{"padding":"0px"},style:({
    'background-image': 'url(' + require(`@/assets/img/backgrounds/${_vm.currentBackground}`) + ')',
}),attrs:{"fluid":"","fill-height":"","id":"HomePage"}},[_c('Volume'),_c('transition',{attrs:{"name":"fade-transition"}},[(_vm.showIntro && _vm.show)?_c('div',{staticClass:"skip custom-font",on:{"click":_vm.skipVideo}},[_vm._v(" Skip Intro ")]):_vm._e()]),_c('transition',{attrs:{"name":"fade-transition"}},[(_vm.showIntro && _vm.$store.state.user.type != 'teacher' && _vm.show)?_c('div',{staticClass:"intro-video-wrapper"},[_c('div',{staticClass:"myVideoWrapper"},[_c('video',{attrs:{"autoplay":"","id":"myVideo","poster":require('@/assets/img/home-video-poster.png')},domProps:{"muted":_vm.$store.state.mute || _vm.introvideoMute},on:{"click":_vm.videoPlay,"ended":_vm.videoEnded,"loadeddata":_vm.checkLoading}},[_c('source',{attrs:{"src":require("@/assets/videos/updated-game-intro.mp4"),"type":"video/mp4"}})]),(_vm.$store.state.mute || _vm.introvideoMute)?_c('v-icon',{staticClass:"mute-btn",on:{"click":_vm.mute_or_unmute}},[_vm._v(" volume_off ")]):_vm._e(),(!_vm.$store.state.mute || !_vm.introvideoMute)?_c('v-icon',{staticClass:"mute-btn",on:{"click":_vm.mute_or_unmute}},[_vm._v(" volume_up ")]):_vm._e()],1)]):_vm._e()]),_c('transition',{attrs:{"name":"fade-transition"}},[(
            _vm.videoLoading &&
            !_vm.introWatched &&
            _vm.$store.state.user.type != 'teacher' &&
            !_vm.show
        )?_c('div',{staticClass:"full-screen-loader"},[_c('v-progress-circular',{staticClass:"progressLoader",attrs:{"indeterminate":"","color":"#000000"}})],1):_vm._e()]),_c('transition',{attrs:{"name":"fade-transition"}},[_c('v-container',[(!_vm.showIntro && _vm.introWatched && _vm.show)?_c('div',{staticClass:"menus-container"},[_c('ul',{staticClass:"menus-heading"},[_c('li',{staticClass:"menu-list-item"},[_c('router-link',{attrs:{"to":"/rooms"}},[_vm._v("ROOMS "),_c('v-icon',{staticClass:"arrow-icon",attrs:{"x-large":""}},[_vm._v("east")])],1)],1),_c('li',{staticClass:"menu-list-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("SCOREBOARD "),_c('v-icon',{staticClass:"arrow-icon",attrs:{"x-large":""}},[_vm._v("west")])],1)],1),_c('li',{staticClass:"menu-list-item"},[_c('router-link',{attrs:{"to":"/settings"}},[_vm._v("SETTINGS "),_c('v-icon',{staticClass:"arrow-icon",attrs:{"x-large":""}},[_vm._v("north")])],1)],1),(_vm.User.type == 'teacher')?_c('li',{staticClass:"menu-list-itemt"},[_c('router-link',{attrs:{"to":"/curriculum"}},[_vm._v("CURRICULUM")])],1):_vm._e()])]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }