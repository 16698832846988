<template>
    <span class="volume-container">
        <input class="volume-slider" id="volume-slider" type="range" name="vol" min="0" max="100" step="1" :value=$store.state.volume>

        <v-icon
            @click="toggleBgSound" v-if="$store.state.bgSoundMute || $store.state.volume <= 1"
            class="volume-icon"
        >
            volume_off
        </v-icon>
        <v-icon
            @click="toggleBgSound" v-if="!$store.state.bgSoundMute"
            class="volume-icon"
        >
            volume_up
        </v-icon>
    </span>
</template>

<script>
export default {
    name: 'Volume',
    mounted(){
        var _this = this;
        /** to link bg music volume with volume slider */
        let volumeUI = document.getElementById('volume-slider');
        var volumeData = JSON.parse(localStorage.getItem('volume'));
        if(volumeData != null){
            _this.$store.state.volume = volumeData.volume;
            _this.$store.state.bgSoundMute =  volumeData.bgSoundMute;
            if(_this.$store.state.bgMusic && volumeData.bgSoundMute == true){
                _this.$store.state.bgMusic.pause();
                _this.$store.state.bgMusic.volume = 0;
            }else if(_this.$store.state.bgMusic){
                _this.$store.state.bgMusic.play();
                _this.$store.state.bgMusic.volume = volumeData.volume / 100;
            }
        }
        volumeUI.addEventListener("change", function(e) {
            _this.$store.state.volume = e.currentTarget.value;
            _this.$store.state.bgMusic.volume = e.currentTarget.value / 100;
            if(_this.$store.state.volume < 0.5){
                _this.$store.state.bgMusic.pause();
                _this.$store.state.bgMusic.volume = 0;
                _this.$store.state.bgSoundMute = true;
            }else{
                _this.$store.state.bgSoundMute = false;
                _this.$store.state.bgMusic.play();
            }
            let volumeData = {
                volume: e.currentTarget.value,
                bgSoundMute: _this.$store.state.bgSoundMute,
            };
            localStorage.setItem('volume', JSON.stringify(volumeData));
        });
    },
    methods: {
        toggleBgSound() {
            if(this.$store.state.bgSoundMute == false){
                this.$store.state.volume = 0;
                this.$store.state.bgMusic.volume = 0;
                this.$store.state.bgSoundMute = true;
                this.$store.state.bgMusic.pause();
                this.$store.state.bgMusic.currentTime = 0;                
            }else{
                this.$store.state.volume = 99;
                this.$store.state.bgMusic.volume = 0.9;
                this.$store.state.bgSoundMute = false;
                this.$store.state.bgMusic.currentTime = 0;
                this.$store.state.bgMusic.play();

            }
            let volumeData = {
                volume: this.$store.state.volume,
                bgSoundMute: this.$store.state.bgSoundMute,
            };
            localStorage.setItem('volume', JSON.stringify(volumeData));
        },
        
    }
}
</script>

<style scoped>

.volume-container{
    position: absolute;
    top: 1%;
    right: 1.5%;
    min-width: 50px;
    min-height: 45px;
    display: block;
}

.volume-icon {
    right: 2%!important;
    top: 1%;
    font-size: 45px!important;
    transform: rotate(180deg);
    cursor: pointer;
    position: fixed!important;
    color: rgba(0,0,0,.70)!important;
}
.volume-slider{
    position: absolute;
    right: 60px;
    top: 12px;
    display: none;
    width: 120px;
    /* accent-color: #BF6902; */
}

.volume-container:hover .volume-slider{
    display: block;
}

.volume-container:hover{
    min-width: 170px;
}

</style>