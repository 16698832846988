import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import { setTimeout } from 'core-js';
const axios = require('axios').default;

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        actionInProgress: false,
        user: null,
        responseMsg: '',
        ResponseClass: '',
        responseData: '',
        fetching: false,
        onRefreshActivitiesNewDataLoad: false,
        showActivitiesLoader: false,
        Rooms: null,
        Lessons: null,
        LearningObjectives: null,
        Activities: null,
        Activity: null,
        objectiveSkip: false,
        introWatched: false,
        activityIntroWatched: false,
        assessmentCoins: 0,
        activityTime: '00:00',
        mute: false,
        profileImage: null,
        nextActivity: null,
        fromLink: null,
        activityShuffled: null,
        RoomDB: null,
        Lessons: null,
        narrationText: '',
        blobENarrationText: '',
        showTwoBNarration: false,
        showTwoBNarrationvertwo: false,
        drAstroNarrationClass: '',
        showDrAstro: false,
        showTwoB: false,
        showDrAstroNarration: false,
        showBlobENarration: false,
        showNarration: false,
        voiceOver: null,
        backgroundMusic: null,
        /** to keep track of current room ID */
        currentRoomID: '',

        /** to keep track of activity number -> activity number is ID of activity from database */
        currentActivityNumber: '',

        /** to keep track of Activity 11 */
        shadowFirstAssessmentDone: false,

        /** to keep track of Activity 10 of unit 2 */
        currentActivityPhase: false,
        /** to store conclusion hypothesis of ACtivity 10 of unit 2 */
        conclusionHypothesis: '',

        /** to keep the blobE narration visible */
        keepShowing: false,
        volume: 99,
        bgSoundMute: false,
        /** to keep the status of current part of the room (1 to 4 or 6 to 8) */
        roomPage: 1,

        // ActivityData
        activityData: null,

        // LearningObjectiveData
        loData: null,
        flagOnLearningObjective: false,
        /** to store all rooms from API */
        roomsList: [],

        /** to store the setInterval for the script to check users' status */
        userStatusInterval: null,

        access_token: null,

        serverLiveURL: process.env.VUE_APP_API_END_POINT,

        headers: {
            key: process.env.VUE_APP_SERVER_KEY,
            secret: process.env.VUE_APP_SERVER_SECRET,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },

        authHeaders: {
            key: process.env.VUE_APP_SERVER_KEY,
            secret: process.env.VUE_APP_SERVER_SECRET,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            authorization: null,
        },
        currentRoom: null,
        bgMusic: null,
        audioInterval: null,

        // overlay settings
        oSettings: false,
        oProfile: false,
    },
    mutations: {
        updateSettingState(state) {
            state.oSettings = !state.oSettings;
            console.log('Setting state: ' + state.oSettings);
        },
        updateProfileState(state) {
            state.oProfile = !state.oProfile;
        },
        SET_FLAG_ON_LEARNING_OBJECTIVE(state, newValue) {
            state.flagOnLearningObjective = newValue;
        },
        setEmptyfield(state) {
            state.user = null;
            state.Rooms = null;
            state.Lessons = null;
            state.LearningObjectives = null;
            state.Activities = null;
            state.Activity = null;
            state.objectiveSkip = false;
            state.fromLink = null;
            state.RoomDB = null;
            localStorage.clear();
        },
        setUser(state, payload) {
            state.user = payload;
        },
        setActionInProgress(state, payload) {
            state.actionInProgress = payload;
        },
        setResponseMessage(state, payload) {
            state.responseMsg = payload;
        },
        setResponseClass(state, payload) {
            state.ResponseClass = payload;
        },
        setResponseData(state, payload) {
            state.responseData = payload;
        },
        setRooms(state, payload) {
            state.Rooms = payload;
        },
        setLessons(state, payload) {
            state.Lessons = payload;
        },
        setActivities(state, payload) {
            state.Activities = payload;
        },
        setActivity(state, payload) {
            state.Activity = payload;
        },
        setAssessmentCoins(state, payload) {
            state.assessmentCoins = payload;
        },
        setActivityTime(state, payload) {
            state.activityTime = payload;
        },
        setMute(state, payload) {
            state.mute = payload;
        },
        setProfileImage(state, payload) {
            state.profileImage = payload;
        },
        setLearningObjectives(state, payload) {
            state.LearningObjectives = payload;
        },
        setShowTwoBNarration(state, payload) {
            state.showTwoBNarration = payload;
        },
        setShowTwoBNarrationvertwo(state, payload) {
            state.showTwoBNarrationvertwo = payload;
        },
        setNarrationText(state, payload) {
            state.narrationText = payload;
        },
        setBlobENarrationText(state, payload) {
            state.blobENarrationText = payload;
        },
        setShowNarration(state, payload) {
            state.showNarration = payload;
        },
        setNarrationErase(state, payload) {
            state.narrationErase = payload;
        },
        setNextNarration(state, payload) {
            state.nextNarration = payload;
        },
        setShowDrAstroNarration(state, payload) {
            state.showDrAstroNarration = payload;
        },
        setShowDrAstro(state, payload) {
            state.showDrAstro = payload;
        },
        setShowBlobENarration(state, payload) {
            state.showBlobENarration = payload;
        },
        setBackgroundMusic(state, payload) {
            state.backgroundMusic = payload;
        },
        /** to set roomsList from data fetched from API */
        setRoomsList(state, payload) {
            state.roomsList = payload;
        },
        /**  */
        setCurrentActivityPhase(state, payload) {
            state.currentActivityPhase = payload;
        },
        setRoomPage(state, payload) {
            state.roomPage = payload;
        },
        setRoomDB(state, payload) {
            state.RoomDB = payload;
        },
    },

    actions: {
        openCloseSettings({ commit }) {
            // console.log('Open settings');
            commit('updateSettingState');
        },
        openCloseProfile({ commit }) {
            commit('updateProfileState');
        },
        initializeDatabase({ commit, state, dispatch }) {
            let RoomDB = null;
            let axiosRequests = [];

            axios
                .get(state.serverLiveURL + '/api/rooms/list/13', {
                    headers: state.authHeaders,
                })
                .then(function (response) {
                    RoomDB = response.data;
                    console.log('response data');
                    console.log(response.data);
                    RoomDB.forEach((room, index) => {
                        let request = axios
                            .get(
                                state.serverLiveURL +
                                    '/api/lessons-with-activities/' +
                                    room.room_id,
                                {
                                    headers: state.authHeaders,
                                }
                            )
                            .then(function (response) {
                                // console.log(response.data)
                                room.lessons = response.data;
                            });
                        axiosRequests.push(request);
                    });
                    Promise.all(axiosRequests).then(function () {
                        // console.log('RoomDB', RoomDB);
                        state.RoomDB = RoomDB;
                        localStorage.setItem('RoomDB', JSON.stringify(RoomDB));
                        commit('setActionInProgress', false);
                        state.showActivitiesLoader = false;
                        state.fetching = false;
                        state.onRefreshActivitiesNewDataLoad = false;
                        // state.actionInProgress = false
                    });
                })
                .catch(function (error) {
                    console.log('inside error');
                    console.log(error);
                });

            if (process.env.NODE_ENV == 'production') {
            } else {
            }
        },
        playBackgroundMusic({ state }, bgAudio) {
            if (state.mute == false) {
                if (state.bgMusic == null) {
                    state.bgMusic = new Audio(
                        require(`@/assets/audio/bg-music/` + bgAudio)
                    );
                }
                let volumeData = JSON.parse(localStorage.getItem('volume'));
                if (volumeData != null) {
                    console.log('If...');
                    state.bgSoundMute = volumeData.bgSoundMute;
                    if (volumeData.bgSoundMute == true) {
                        state.volume = volumeData.volume;
                        state.bgMusic.pause();
                        state.bgMusic.currentTime = 0;
                    } else {
                        state.volume = volumeData.volume;
                        state.bgMusic.play();
                        state.bgMusic.volume = volumeData.volume / 100.0;
                    }
                } else {
                    state.bgMusic.play();
                    state.bgMusic.loop = true;
                    state.bgMusic.volume = 0.0;
                    state.volume = 100;
                    state.bgSoundMute = false;
                    if (state.audioInterval) {
                        clearInterval(state.audioInterval);
                    }
                    state.audioInterval = setInterval(() => {
                        if (state.bgMusic.volume >= 0.9) {
                            clearInterval(state.audioInterval);
                        } else {
                            state.bgMusic.volume = state.bgMusic.volume + 0.01;
                        }
                    }, 10);
                }
            }
        },
        pauseBackgroundMusic({ state }) {
            if (state.bgMusic) {
                if (state.audioInterval) {
                    clearInterval(state.audioInterval);
                }
                state.audioInterval = setInterval(() => {
                    if (state.bgMusic.volume <= 0.01) {
                        clearInterval(state.audioInterval);
                        state.bgMusic.pause();
                    } else {
                        state.bgMusic.volume = state.bgMusic.volume - 0.01;
                    }
                }, 10);
            }
        },
        stopBackgroundMusic({ state }) {
            if (state.bgMusic) {
                if (state.audioInterval) {
                    clearInterval(state.audioInterval);
                }
                state.audioInterval = setInterval(() => {
                    if (state.bgMusic.volume <= 0.01) {
                        clearInterval(state.audioInterval);
                        state.bgMusic.pause();
                        state.bgMusic.currentTime = 0;
                    } else {
                        state.bgMusic.volume = state.bgMusic.volume - 0.01;
                    }
                }, 10);
            }
        },
        fadeDownBackgroundMusic({ state }) {
            if (state.bgMusic) {
                if (state.audioInterval) {
                    clearInterval(state.audioInterval);
                }
                state.audioInterval = setInterval(() => {
                    if (state.bgMusic.volume <= 0.01) {
                        clearInterval(state.audioInterval);
                    } else {
                        state.bgMusic.volume = state.bgMusic.volume - 0.01;
                    }
                }, 10);
            }
        },
        fadeUpBackgroundMusic({ state }) {
            if (state.bgMusic) {
                if (state.audioInterval) {
                    clearInterval(state.audioInterval);
                }
                state.audioInterval = setInterval(() => {
                    if (state.bgMusic.volume >= 0.6) {
                        clearInterval(state.audioInterval);
                    } else {
                        state.bgMusic.volume = state.bgMusic.volume + 0.01;
                    }
                }, 10);
            }
        },
        getNextActivity({ state }, { Activity_id }) {
            var Activities = state.Activities;
            // console.log("Activities: " + JSON.stringify(Activities));
            var nextItem;
            var nextItemSelected = null;
            console.log(Activities);
            var index = Activities.findIndex(
                (x) => x.activity_code == Activity_id
            );
            // if (index >= 0 && index < Activities.length - 1) {
            //     nextItem = Activities[index + 1];
            // }

            while (index >= 0 && index < Activities.length - 1) {
                nextItem = Activities[index + 1];
                if (nextItem.is_unlock == 1 && nextItem.status == '1') {
                    nextItemSelected = nextItem;
                    break;
                }
                index++;
            }

            state.nextActivity = nextItemSelected;
            // state.nextActivity = nextItem;
        },
        userLogin({ commit, state, dispatch }, credentials) {
            dispatch('initializeDatabase');
            axios
                .post(state.serverLiveURL + '/api/login', credentials, {
                    headers: state.headers,
                })
                .then(function (response) {
                    // handle success
                    commit('setUser', response.data[0]);
                    state.access_token = response.data[0].access_token;
                    state.authHeaders.authorization =
                        'Bearer ' + response.data[0].access_token;
                    localStorage.setItem(
                        'user',
                        JSON.stringify(response.data[0])
                    );

                    dispatch('checkUserStatus').then(() => {
                        state.userStatusInterval = setInterval(() => {
                            dispatch('checkUserStatus');
                        }, 5000);
                        if (state.user.status == 'active') {
                            commit('setActionInProgress', false);
                            router.push({ path: '/' });
                            dispatch(
                                'playBackgroundMusic',
                                'supersci-bg-music.mp3'
                            );
                        } else {
                            commit(
                                'setResponseMessage',
                                'You have been paused by your teacher. Please contact your teacher.'
                            );
                            commit('setResponseClass', 'error');
                            commit('setActionInProgress', false);
                            clearInterval(state.userStatusInterval);
                        }
                    });
                })
                .catch(function (error) {
                    if (
                        error &&
                        error.response.status >= 500 &&
                        error.response.status <= 599
                    ) {
                        commit('setResponseMessage', 'Network error.');
                    } else {
                        commit(
                            'setResponseMessage',
                            'Incorrect username or password.'
                        );
                    }
                    commit('setResponseClass', 'error');
                    commit('setUser', null);
                    commit('setActionInProgress', false);
                });
        },
        userLogout({ commit, dispatch }) {
            localStorage.clear();
            commit('setEmptyfield');
            dispatch('stopBackgroundMusic', 'supersci-bg-music.mp3');
            router.push({ path: '/sign-in' }).catch(() => {});
        },
        getRooms({ commit, state }) {
            var Rooms = [
                { id: '02', title: 'Matter', color: '#F0529D' },
                { id: '03', title: 'Energy In Motion', color: '#E76600' },
                { id: '04', title: 'Forces', color: '#EE252F' },
                {
                    id: '6a6b5c5d9e9',
                    title: 'Earth and Space',
                    color: '#6B2EB9',
                },
                { id: '05', title: 'Weather and Climate', color: '#1D72EF' },
                {
                    id: '06',
                    title: 'Diversity of Living Things',
                    color: '#00A47F',
                },
                { id: '07', title: 'Living Organisms', color: '#007540' },
                { id: '08', title: 'Weather and Climate', color: '#CC8F00' },
            ];
            commit('setRooms', Rooms);
            state.fetching = false;
        },
        getLearningObjective({ commit, state }, { Room_id, Lesson_id }) {
            var objectives = [];
            let findedActivity = null;
            let findedFirstActivityID = null;
            if (!state.RoomDB) {
                state.RoomDB = JSON.parse(localStorage.getItem('RoomDB'));
            }
            const room = state.RoomDB.find((r) => r.room_id == Room_id);

            if (room) {
                findedActivity = room.lessons.find((l) => l.id == Lesson_id).lo;

                // Sort incase if activitiesID isn't sorted in API
                // findedFirstActivityID = room.lessons.find(l => l.id == Lesson_id).activities[0]
                findedFirstActivityID = room.lessons.find(
                    (l) => l.id == Lesson_id
                ).activities;
                let index = 0;
                let nextItem = null;
                let nextItemSelected = null;

                while (
                    index >= 0 &&
                    index <= findedFirstActivityID.length - 1
                ) {
                    nextItem = findedFirstActivityID[index];
                    if (nextItem.is_unlock == 1 && nextItem.status == '1') {
                        nextItemSelected = nextItem;
                        break;
                    }
                    index++;
                }

                findedFirstActivityID = nextItemSelected;
            }

            if (findedActivity != null && findedActivity?.type == 'new') {
                let url = new URL(findedActivity.src);
                objectives = {
                    id: findedActivity.id,
                    type: 'articulate-learning-activities',
                    src: url.pathname.toString(),
                    PrimaryMsg:
                        findedActivity.primary_msg == null
                            ? 'No msg is displayed'
                            : findedActivity.primary_msg,
                    background: 'green-organisms-bg.png',
                    // background: findedActivity.background == null ? 'green-organisms-bg.png' : findedActivity.background,
                    lesson_id: Lesson_id,
                    first_activity_id: findedFirstActivityID
                        ? findedFirstActivityID.activity_code
                        : null,
                    second_activity_id: findedFirstActivityID
                        ? findedFirstActivityID.activity_code
                        : null,
                    activity_type: findedFirstActivityID
                        ? findedFirstActivityID.activity_type
                        : null,
                };
            } else {
                objectives = {};
                // commit('setLearningObjectives', {});
                // state.fetching = false;
            }

            state.RoomDB.forEach((element) => {
                if (element.lessons.find((e) => e.id == Lesson_id)) {
                    objectives.room_id = element.id;
                    element.lessons.find(
                        (e) => e.id == Lesson_id
                    ).LearningObjectivesVisited = 'visited';
                }
            });

            if (objectives) {
                const current_LO = {
                    lesson_id: Lesson_id,
                    first_activity: objectives.first_activity_id,
                    room_id: Room_id,
                    activity_type: objectives.type,
                    second_activity_id: objectives.second_activity_id,
                };
                localStorage.setItem('current_LO', JSON.stringify(current_LO));
                let activityData = {
                    roomID: Room_id,
                    lessonID: Lesson_id,
                    activityID: objectives.first_activity_id,
                    activity_type: objectives.type,
                    second_activity_id: objectives.second_activity_id,
                };
                localStorage.setItem(
                    'activity_data',
                    JSON.stringify(activityData)
                );
            } else {
                localStorage.removeItem('current_LO');
            }
            if (!objectives.type && findedActivity?.type == 'old') {
                objectives.type = '';
                objectives.old_activity = true;
            } else {
                objectives.old_activity = false;
            }

            commit('setLearningObjectives', objectives);
            state.fetching = false;
        },
        getLessons({ commit, state, dispatch }, Room_id) {
            return axios
                .get(state.serverLiveURL + '/api/lesson/list/' + Room_id, {
                    headers: state.authHeaders,
                })
                .then(function (response) {
                    // handle success
                    // sort by name
                    response.data.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });
                    state.showActivitiesLoader = true;
                    commit('setLessons', response.data);
                    const promises = [];
                    // Lessons check
                    // .includes("lesson")
                    response.data = response.data.filter((lesson) =>
                        lesson.name.toLowerCase()
                    );
                    response.data.forEach((lesson) => {
                        promises.push(
                            dispatch('getActivitiesByLessonID', {
                                Room_id: Room_id,
                                Lesson_id: lesson.id,
                            })
                        );
                    });
                    Promise.all(promises).then(() => {
                        state.showActivitiesLoader = false;
                        state.fetching = false;
                    });
                })
                .catch(function (error) {
                    // handle error
                    var Lessons = [];
                    console.log(error);
                    if (error && error.response?.status == 401) {
                        localStorage.removeItem('user');
                        router.push({ path: '/sign-in' });
                        commit('setEmptyfield');
                        state.fetching = false;
                    } else if (state.RoomDB) {
                        commit('setLessons', Lessons);
                        state.fetching = false;
                    }
                })
                .finally(() => {
                    state.fetching = false;
                });
        },
        getActivitiesByLessonID(
            { /*commit, */ state /*, dispatch */ },
            { Room_id, Lesson_id }
        ) {
            var lessonsActivities = null;
            var Activities = null;
            axios
                .get(state.serverLiveURL + '/api/activites/list/' + Lesson_id, {
                    headers: state.authHeaders,
                })
                .then(function (response) {
                    lessonsActivities = response.data;
                    // find that lesson in local db
                    if (
                        state.RoomDB !== null &&
                        state.RoomDB[0] !== null &&
                        state.RoomDB[0]?.lessons !== null &&
                        state.RoomDB[0]?.lessons[0] !== null &&
                        state.RoomDB[0]?.lessons[0]?.activities !== null &&
                        state.RoomDB[0]?.lessons[0]?.activities[0] !== null
                    ) {
                        state.RoomDB.forEach((element) => {
                            if (element.id == Room_id) {
                                if (
                                    element.Lessons &&
                                    element.Lessons.find(
                                        (e) => e.id == Lesson_id
                                    )
                                ) {
                                    Activities = element.Lessons.find(
                                        (e) => e.id == Lesson_id
                                    ).Activities;

                                    Activities.forEach((activity) => {
                                        activity.lock_status = 'disabled';
                                        const lessonActivity =
                                            lessonsActivities.find(
                                                (e) =>
                                                    e.id ==
                                                    activity.activity_number
                                            );

                                        if (lessonActivity != undefined) {
                                            if (lessonActivity.is_unlock == 1) {
                                                activity.lock_status = 'active';
                                            }
                                            // set activity number from database
                                            activity.activity_number =
                                                lessonActivity.id;
                                            let tempActivity = lessonActivity;
                                            activity.is_completed =
                                                tempActivity
                                                    .user_activity_points_check
                                                    .length > 0
                                                    ? true
                                                    : false;
                                        }
                                    });
                                }
                            }
                        });
                    }
                });
        },
        getActivities({ commit, state, dispatch }, { Lesson_id, Room_id }) {
            var Activities;
            let RoomDB = [];
            if (state.RoomDB) {
                RoomDB = state.RoomDB;
            } else {
                RoomDB = JSON.parse(localStorage.getItem('RoomDB'));
            }

            if (
                RoomDB !== null &&
                RoomDB[0] !== null &&
                RoomDB[0]?.lessons !== null &&
                RoomDB[0]?.lessons[0] !== null &&
                RoomDB[0]?.lessons[0]?.activities !== null &&
                RoomDB[0]?.lessons[0]?.activities[0] !== null
            ) {
                RoomDB.forEach((element) => {
                    if (element.room_id == Room_id) {
                        // console.log('Finded Rooms', element);
                        // console.log('ELEMENT.LESSONS', element.lessons);
                        if (
                            element.lessons &&
                            element.lessons.find((e) => e.id == Lesson_id)
                        ) {
                            // console.log('Lessons', element.lessons);
                            Activities = element.lessons.find(
                                (e) => e.id == Lesson_id
                            ).activities;
                        }
                    } else {
                        if (
                            element.lessons &&
                            element.lessons.find((e) => e.id == Lesson_id)
                        ) {
                            Activities = element.lessons.find(
                                (e) => e.id == Lesson_id
                            ).activities;
                        }
                    }
                });

                // Order of Activities as per curriculum
                Activities = Activities.sort((a, b) => {
                    const activityNumberA = parseInt(
                        a.name.split('Activity ')[1]
                    );
                    const activityNumberB = parseInt(
                        b.name.split('Activity ')[1]
                    );
                    return activityNumberA - activityNumberB;
                });
                // console.log(Activities);
                commit('setActivities', Activities);
                localStorage.setItem(
                    'ACTIVITIES_RUN',
                    JSON.stringify(Activities)
                );
                state.fetching = false;
            }
        },
        getActivity({ state, commit, dispatch }, activity_id) {
            var Activity;
            var findedActivity;

            // Activity = window.localStorage.getItem('activity_data');
            // console.log(activity_id)scoreboard_data
            // console.log("*******ACTIVITY LOCAL STORAGE***********", state.activityData)
            let activityData = JSON.parse(
                localStorage.getItem('activity_data')
            );
            let scoreboardData = JSON.parse(
                localStorage.getItem('scoreboard_data')
            );
            let RoomDB = JSON.parse(localStorage.getItem('RoomDB'));
            console.log('activityData', activityData);

            console.log(activity_id);
            console.log('activity_data: ', activityData);
            console.log(
                'Inside GetActivity',
                JSON.parse(localStorage.getItem('RoomDB'))
            );

            if (scoreboardData) {
                findedActivity = scoreboardData;
            } else {
                const room = RoomDB.find(
                    (r) => r.room_id == activityData.roomID
                );
                let activity;
                if (room) {
                    console.log(room);
                    const lesson = room.lessons.find(
                        (l) => l.id == activityData.lessonID
                    );
                    if (lesson) {
                        console.log(lesson);
                        if (activityData.activity_type == 'old') {
                            // activity = lesson.activities.find(a => a.activity_code == activity_id);
                            activity = lesson.activities.find(
                                (a) => a.activity_code == activity_id
                            );
                        } else {
                            // activity = lesson.activities.find(a => a.id == activity_id);
                            activity = lesson.activities.find(
                                (a) =>
                                    a.activity_code == activityData.activityID
                            );
                            // activity = lesson.activities.find(a => a.activity_code == activityData.second_activity_id);
                        }
                        if (activity) {
                            // activity found, do something with it
                            findedActivity = activity;
                            // console.log(findedActivity);
                        }
                    }
                    // }
                }
            }
            console.log(findedActivity);

            localStorage.removeItem('scoreboard_data');
            if (
                findedActivity.src != null &&
                /*findedActivity.activity_type !== "old" || */ findedActivity.activity_type ==
                    'new'
            ) {
                let url = new URL(findedActivity.src);
                Activity = {
                    id: findedActivity.activity_code,
                    type: 'articulate-' + findedActivity.css_class,
                    // src: findedActivity.src
                    src: url.pathname.toString(),
                };
                localStorage.setItem('ACTIVITY_DEMO', JSON.stringify(Activity));
                console.log('Building ACTIVITY', url.pathname.toString());
                // alert("Actiity")
            } /* if (findedActivity.activity_type == "old") */ else {
                if (activity_id == '011') {
                    // Activity = {
                    //     id: '011',
                    //     type: 'articulate-key-science-lingo-right',
                    //     src: '/ThirdParty/EarthAndSpace/TheMilkyWay/Activity1/story.html',
                    // };
                    Activity = {
                        id: '011',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1',
                        starting_animation: 'Jump-out.mp4',
                        ending_animation: 'jumps-in.mp4',
                        starting_background: 'Key-Lingo-Background-wreaked.jpg',
                        ending_background: 'Key-Lingo-Background-clean.jpg',
                        narration_2b: [
                            {
                                text: 'Dr. Astro! Everything is destroyed! Kids can you help me restore this?',
                                position: 'start',
                                voice_over: '2BR1L1.mp3',
                            },
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Planet',
                                answer: 'A large body in space that orbits a star and does not produce light of its own.',
                            },
                            {
                                question: 'Galaxy',
                                answer: 'A system of millions or billions of stars, together with gas and dust, held together by gravitational attraction.',
                            },
                            {
                                question: 'Milky Way Galaxy',
                                answer: 'A large spiral system consisting of several hundred billion stars, one of which is the Sun.',
                            },
                            {
                                question: 'Nebula',
                                answer: 'A giant cloud of gas and dust in outer space, regions where new stars are beginning to form.',
                            },
                            {
                                question: 'Solar System',
                                answer: 'A system of planets and other bodies that orbits a star.',
                            },
                            {
                                question: 'Star',
                                answer: 'A large object in space that is made of gas and produces its own light.',
                            },
                            {
                                question: 'Light Year',
                                answer: 'The distance that light travels through empty space in one year.',
                            },
                        ],
                    };
                } else if (activity_id == '012') {
                    Activity = {
                        id: '012',
                        type: 'articulate-examiner',
                        src: '/ThirdParty/EarthAndSpace/TheMilkyWay/Activity1A/story.html',
                    };
                } else if (activity_id == '013') {
                    // Activity = {
                    //     id: '013',
                    //     type: 'puzzle',
                    // };
                    Activity = {
                        id: '013',
                        type: 'articulate-puzzle',
                        src: '/ThirdParty/EarthAndSpace/TheMilkyWay/Activity2/story.html',
                    };
                }
                // else if (activity_id == '999') {
                //     Activity = {
                //         id: '999',
                //         type: 'ThirdPartyActivity',
                //         src: '/ThirdParty/earthnspace/Lessons1/DemoActivity/index.html'
                //     };
                // }
                else if (activity_id == '014') {
                    // Activity = {
                    //     id: '014',
                    //     type: 'articulate-assessment1',
                    //     src: '/ThirdParty/EarthAndSpace/TheMilkyWay/Activity3/story.html',
                    // };
                    Activity = {
                        id: '014',
                        type: 'assessment1',
                        class: 'assessment earth-assessment',
                        starting_animation: '',
                        ending_animation: '',
                        background: 'purple-galaxy-background.png',
                        narration_2b: [
                            {
                                text: 'Click on the best answer possible.',
                                position: 'hint',
                                voice_over: '2BPU8a.mp3',
                            },
                        ],
                        dr_astro_narration: [
                            {
                                text: 'In our investigation today you have made many observations. Everything in space is related in some way to everything else. Everything is connected. The Universe is a system of connections. Using the observations you made throughout this lesson.',
                                position: 'beforeActivityStart',
                                voice_over: 'U1-L1-A3-S1.mp3',
                            },
                            {
                                text: 'Please describe these relationships that explain the organization of the Universe by reading the question and selecting the right answer to the corresponding question.',
                                position: 'beforeActivityStart2',
                                voice_over: 'U1-L1-A3-S2.mp3',
                            },
                        ],
                        assets: [
                            {
                                question:
                                    'How are dust and gas related to a nebula?',
                                answer: 'A nebula is composed of dust and gas.',
                                available_options: [
                                    {
                                        text: 'A nebula is composed of dust and gas.',
                                    },
                                    {
                                        text: 'Dust and gas are made up of nebula.',
                                    },
                                    {
                                        text: 'Dust, gas, and nubula are needed to form a planet.',
                                    },
                                ],
                            },
                            {
                                question: 'How is a nebula related to a star?',
                                answer: 'Stars are born in the center of a collapsed nebula.',
                                available_options: [
                                    {
                                        text: 'Nebula and stars do not rely on gravity to hold their components in place.',
                                    },
                                    {
                                        text: 'Stars are born in the center of a collapsed nebula.',
                                    },
                                    {
                                        text: 'Stars and nebula are only formed in the Milky Way galaxy.',
                                    },
                                ],
                            },
                            {
                                question: 'How are stars related to a galaxy?',
                                answer: 'All galaxies are made up of stars.',
                                available_options: [
                                    {
                                        text: 'Stars are located in only in the arm of our galaxy, the Milky Way.',
                                    },
                                    {
                                        text: 'Stars travel in paths called orbits around a galaxy.',
                                    },
                                    {
                                        text: 'All galaxies are made up of stars.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'How is a galaxy related to a solar system?',
                                answer: 'Galaxies are a system of stars and solar systems.',
                                available_options: [
                                    {
                                        text: 'Galaxies can be found only in our solar system.',
                                    },
                                    {
                                        text: 'Galaxies are a system of stars and solar systems.',
                                    },
                                    {
                                        text: 'Neither galaxies or solar systems require the force of gravity to keep their components in place.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What force holds all of the components of a solar system in their place?',
                                answer: 'Gravitational force.',
                                available_options: [
                                    {
                                        text: 'Magnetic force.',
                                    },
                                    {
                                        text: 'Electrical force.',
                                    },
                                    {
                                        text: 'Gravitational force.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What do we call the path that a planet travels on?',
                                answer: 'Planets travel in paths called orbits.',
                                available_options: [
                                    {
                                        text: 'Planets travel in paths called orbits.',
                                    },
                                    {
                                        text: 'Planets travel in paths called routes.',
                                    },
                                    {
                                        text: 'Planets travel in paths called courses.',
                                    },
                                ],
                            },
                            {
                                question: 'Where is our solar system located?',
                                answer: ' It is located in the arm of the Milky Way galaxy.',
                                available_options: [
                                    {
                                        text: 'It is located in the center of the Milky Way galaxy.',
                                    },
                                    {
                                        text: 'It is located in the arm of the Milky Way galaxy.',
                                    },
                                    {
                                        text: 'It is located in the arm of the Andromeda galaxy.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What is the name of the galaxy we live in?',
                                answer: 'We live in the Milky Way Galaxy.',
                                available_options: [
                                    {
                                        text: 'We live in the Andromeda Galaxy.',
                                    },
                                    {
                                        text: 'We live in the Milky Way Galaxy.',
                                    },
                                    {
                                        text: 'We live in an elliptical galaxy.',
                                    },
                                ],
                            },
                        ],
                    };
                } else if (activity_id == '015') {
                    // Activity = {
                    //     id: '015',
                    //     type: 'articulate-key-science-lingo-left',
                    //     src: '/ThirdParty/EarthAndSpace/TheSolarSystem/Activity4/story.html',
                    // };
                    Activity = {
                        id: '015',
                        type: 'key-science-lingo-left',
                        class: 'key-science-lingo-2',
                        starting_animation: '2b-jet-pack-jump-out.mp4',
                        ending_animation: '2b-jetpack-jump-in.mp4',
                        starting_background: 'purple-galaxy-background.png',
                        ending_background: 'purple-galaxy-background.png',
                        narration_2b: [
                            {
                                text: 'We are now in the solar system, can you help me put together these definitions with their corresponding words?',
                                position: 'start',
                                voice_over: '2BR1L13.mp3',
                            },
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Rotation',
                                answer: 'The movement of an object in a circular motion, the action of rotating around an axis or center.',
                            },
                            {
                                question: 'Asteroid',
                                answer: 'A rocky or metallic object that orbits the Sun and is much smaller than a planet.',
                            },
                            {
                                question: 'Meteor',
                                answer: 'A small body of matter that enters Earth’s atmosphere due to gravity, appearing as a streak of light as a result of being heated.',
                            },
                            {
                                question: 'Moon',
                                answer: 'A natural satellite that revolves around a planet.',
                            },
                            {
                                question: 'Comet',
                                answer: 'An object made of rock, ice, dust, and gas that revolves around the Sun.',
                            },
                            {
                                question: 'Mass',
                                answer: 'A measurement of how much matter is in an object.',
                            },
                            {
                                question: 'State of Matter',
                                answer: 'The phases that matter can take; solid, liquid or gas.',
                            },
                            {
                                question: 'Orbit',
                                answer: 'A repeating path that one object in space takes around another.',
                            },
                        ],
                    };
                } else if (activity_id == '016') {
                    Activity = {
                        id: '016',
                        type: 'articulate-exploration',
                        Template: 'Room1Lesson2Activity4A',
                        src: '/ThirdParty/EarthAndSpace/TheSolarSystem/Activity4A/story.html',
                    };
                } else if (activity_id == '017') {
                    Activity = {
                        id: '017',
                        type: 'articulate-solarSystemActivity5',
                        src: '/ThirdParty/EarthAndSpace/TheSolarSystem/Activity5/story.html',
                    };
                    // Activity = {
                    //     id: '017',
                    //     type: 'solarSystemActivity5',
                    // };
                }
                // else if (activity_id == '018') {
                //     Activity = {
                //         id: '018',
                //         type: 'solarSystemActivity5A',
                //     };
                // }
                else if (activity_id == '019') {
                    // Activity = {
                    //     id: '019',
                    //     type: 'articulate-key-science-lingo-left',
                    //     src: '/ThirdParty/EarthAndSpace/TheSolarSystem/Activity6/story.html',
                    // };
                    Activity = {
                        id: '019',
                        type: 'key-science-lingo-left',
                        class: 'key-science-lingo-2',
                        starting_animation: '2b-jet-pack-jump-out.mp4',
                        ending_animation: '2b-jetpack-jump-in.mp4',
                        starting_background: 'purple-galaxy-background.png',
                        ending_background: 'purple-galaxy-background.png',
                        narration_2b: [
                            {
                                text: 'Can you help me put together these definitions with their corresponding words?',
                                position: 'start',
                                voice_over: 'U1-L2-A5-S1.mp3',
                            },
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Diameter',
                                answer: 'A straight line passing from side to side through the center of a circle or sphere.',
                            },
                            {
                                question: 'Axis',
                                answer: 'An invisible line through the center of a planet from top to bottom around which an object rotates, or spins',
                            },
                            {
                                question: 'Inner Planets',
                                answer: 'Are terrestrial planets closest to the Sun: Mercury, Venus, Earth, and Mars.',
                            },
                            {
                                question: 'Outer Planets',
                                answer: 'Are gaseous planets are Jupiter, Saturn, Uranus, and Neptune.',
                            },
                            {
                                question: 'Asteroid Belt',
                                answer: 'A region of many solid, irregularly shaped bodies, of many sizes called asteroids in our Solar System, located between the orbits of the planets Jupiter and Mars.',
                            },
                            {
                                question: 'Atmosphere',
                                answer: 'A gaseous envelope surrounding a celestial body or planet.',
                            },
                            {
                                question: 'Gaseous',
                                answer: 'Having the form of or being gas.',
                            },
                            {
                                question: 'Terrestrial',
                                answer: 'Things related to land, a planet having a compact, rocky surface.',
                            },
                            {
                                question: 'Day',
                                answer: 'Approximately the time during which a planet completes one rotation around its axis.',
                            },
                        ],
                    };
                } else if (activity_id == '020') {
                    // Activity = {
                    //     id: '020',
                    //     type: 'articulate-assessment1',
                    //     src: '/ThirdParty/EarthAndSpace/TheSolarSystem/Activity6A/story.html',
                    // };
                    Activity = {
                        id: '020',
                        type: 'assessment1',
                        class: 'assessment',
                        starting_animation: '',
                        ending_animation: '2b-goes-to-earth.mp4',
                        background: 'purple-galaxy-background.png',
                        narration_2b: [
                            {
                                text: 'Click on the best answer possible.',
                                position: 'hint',
                                voice_over: '2BPU8a.mp3',
                            },
                        ],
                        dr_astro_narration: [{ text: '', position: '' }],
                        assets: [
                            {
                                question:
                                    'What similar characteristics does each inner planet have?',
                                answer: 'Solid or a terrestrial planet',
                                available_options: [
                                    { text: 'Solid or a terrestrial planet' },
                                    { text: 'Molded or a terrestrial planet' },
                                    {
                                        text: 'Solid or a non-terrestrial planet',
                                    },
                                ],
                            },

                            {
                                question:
                                    'What similar characteristics does each outer planet have?',
                                answer: 'Gas or a gaseous planet',
                                available_options: [
                                    { text: 'Solid or a terrestrial planet' },
                                    { text: 'Gas or a gaseous planet' },
                                    { text: 'non-gaseous planet' },
                                ],
                            },

                            {
                                question:
                                    'Which planet is Earth in relation to the Sun?',
                                answer: 'Third planet from the Sun',
                                available_options: [
                                    { text: 'First planet from the Sun' },
                                    { text: 'Second planet from the Sun' },
                                    { text: 'Third planet from the Sun' },
                                ],
                            },

                            {
                                question:
                                    'What have you learned about the planets? What have you concluded?\nSelect the true statement',
                                answer: 'Earth is the only known habitable planet in our solar system.',
                                available_options: [
                                    {
                                        text: 'All planets have the same length of days.',
                                    },
                                    {
                                        text: 'Earth is the only known habitable planet in our solar system.',
                                    },
                                    { text: 'All planets have moons.' },
                                ],
                            },

                            {
                                question: 'Select the true statement.',
                                answer: 'The asteroid Belt is between the inner planets and the outer planets.',
                                available_options: [
                                    {
                                        text: 'The asteroid Belt is between the inner planets and the outer planets.',
                                    },
                                    { text: 'All planets have moons.' },
                                    {
                                        text: 'Earth is the smallest out of all the planets',
                                    },
                                ],
                            },

                            {
                                question: 'Select the true statement.',
                                answer: 'Earth is the largest of the inner planets.',
                                available_options: [
                                    {
                                        text: 'Comets and Meteoroids do NOT orbit the Sun.',
                                    },
                                    {
                                        text: 'All planets have the same length of days.',
                                    },
                                    {
                                        text: 'Earth is the largest of the inner planets.',
                                    },
                                ],
                            },

                            {
                                question:
                                    'When you learned about our solar system what types of investigation methods did you use?',
                                answer: 'Models and observations.',
                                available_options: [
                                    { text: 'Simulations' },
                                    { text: 'Models and observations.' },
                                    { text: 'A controlled experiment.' },
                                ],
                            },
                        ],
                    };
                } else if (activity_id == '021') {
                    // Activity = {
                    //     id: '021',
                    //     type: 'articulate-key-science-lingo-right',
                    //     src: '/ThirdParty/EarthAndSpace/Earth/Activity7/story.html',
                    // };
                    Activity = {
                        id: '021',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 earth-ksl',
                        starting_animation: 'jumps-out-earth.mp4',
                        ending_animation: 'jumps-in-earth.mp4',
                        starting_background: 'beach-night-stars-light.jpg',
                        ending_background: 'beach-night-stars-light.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Apparent Brightness',
                                answer: "The rate at which a star's radiated energy reaches an observer on Earth; depends on both luminosity and distance.",
                            },
                            {
                                question: 'Line Graph',
                                answer: 'A type of chart used to show information or data that changes over time, several points connected together,  forming a straight line.',
                            },
                            {
                                question: 'Luminosity',
                                answer: 'The rate at which a star radiates energy into space.',
                            },
                            {
                                question: 'Shadow',
                                answer: 'A dark area or shape produced by a body coming between rays of light and a surface.',
                            },
                            {
                                question: 'Sunrise',
                                answer: "The daily appearance of the Sun as it rises above the horizon due to Earth's rotation.",
                            },
                            {
                                question: 'Sunset',
                                answer: "The daily disappearance of the Sun below the horizon due to Earth's rotation.",
                            },
                            {
                                question: 'Mass',
                                answer: 'A measure of how much matter is in an object.',
                            },
                        ],
                    };
                } else if (activity_id == '022') {
                    Activity = {
                        id: '022',
                        type: 'articulate-exploring-stars',
                        src: '/ThirdParty/EarthAndSpace/Earth/Activity8/story.html',
                    };
                    // Activity = {
                    //     id: '022',
                    //     type: 'exploring-stars',
                    // };
                } else if (activity_id == '023') {
                    Activity = {
                        id: '023',
                        type: 'articulate-EarthActivity9Exploring',
                        src: '/ThirdParty/EarthAndSpace/Earth/Activity9/story.html',
                    };
                    // Activity = {
                    //     id: '023',
                    //     type: 'EarthActivity9Exploring',
                    // };
                } else if (activity_id == '024') {
                    Activity = {
                        id: '024',
                        type: 'articulate-exploring',
                        src: '/ThirdParty/EarthAndSpace/Earth/Activity10/story.html',
                    };
                    // Activity = {
                    //     id: '024',
                    //     type: 'exploring',
                    // };
                } else if (activity_id == '025') {
                    Activity = {
                        id: '025',
                        type: 'articulate-EarthActivityShadows',
                        src: '/ThirdParty/EarthAndSpace/Earth/Activity11/story.html',
                    };
                    // Activity = {
                    //     id: '025',
                    //     type: 'EarthActivityShadows',
                    // };
                } else if (activity_id == '026') {
                    Activity = {
                        id: '026',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 matter-ksl custom-ghost',
                        starting_animation: '2B_DoorAnimation.mp4',
                        ending_animation: '2B_DoorAnimation-1.mp4',
                        starting_background: 'BG_2.jpg',
                        ending_background: 'BG_2.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Matter',
                                answer: 'Anything that takes up space and has mass.',
                            },
                            {
                                question: 'Mass',
                                answer: 'A measurement of how much matter is in an object.',
                            },
                            {
                                question: 'Weight',
                                answer: "The measure of the amount of gravitational force acting on an object's mass.",
                            },
                            {
                                question: 'Atom',
                                answer: 'The smallest unit of an element or pure substance that retains the properties of that element.',
                            },
                            {
                                question: 'Nucleus',
                                answer: 'A collection of particles called protons, which are positively charged, and neutrons, which are electrically neutral.',
                            },
                            {
                                question: 'Atomic Particles',
                                answer: 'The pieces that make up an atom; protons, neutrons and electrons.',
                            },
                            {
                                question: 'Element',
                                answer: 'A substance that cannot be broken down into any other substance.',
                            },
                        ],
                    };
                } else if (activity_id == '027') {
                    Activity = {
                        id: '027',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 matter-ksl custom-ghost',
                        starting_animation: '2B_DoorAnimation.mp4',
                        ending_animation: '2B_DoorAnimation-1.mp4',
                        starting_background: 'BG_2.jpg',
                        ending_background: 'BG_2.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Temperature',
                                answer: 'A degree of hotness or coldness that can be measured using a thermometer.',
                            },
                            {
                                question: 'Molecule',
                                answer: 'A particle made up of a group of atoms that are chemically bonded together.',
                            },
                            {
                                question: 'Beaker',
                                answer: 'A wide cylindrical glass vessel with measurement increments and a pouring lip.',
                            },
                            {
                                question: 'Flask',
                                answer: 'An upside down funnel shaped glass vessel used in scientific laboratories.',
                            },
                            {
                                question: 'Atomic Theory',
                                answer: 'Elements are made of small particles called atoms.',
                            },
                            {
                                question: 'Chemical Bonding',
                                answer: 'A strong force between atoms, ions or molecules that enables the formation of molecules and chemical compounds.',
                            },
                            {
                                question: 'Heat Energy',
                                answer: 'The energy an object has because of the movement of its molecules.',
                            },
                        ],
                    };
                } else if (activity_id == '028') {
                    Activity = {
                        id: '028',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 matter-ksl custom-ghost',
                        starting_animation: '2B_DoorAnimation-1.mp4',
                        ending_animation: '2B_DoorAnimation.mp4',
                        starting_background: 'BG_2.jpg',
                        ending_background: 'BG_2.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Solid',
                                answer: 'A fundamental state of matter having a definite shape and a definite volume.',
                            },
                            {
                                question: 'Liquid',
                                answer: 'A fundamental state of matter with a definite volume but no definite shape.',
                            },
                            {
                                question: 'Gas',
                                answer: 'A fundamental state of matter in which molecules do not have a fixed volume or shape.',
                            },
                            {
                                question: 'Celsius',
                                answer: 'The most common temperature scale in the world; abbreviated as °C.',
                            },
                            {
                                question: 'Fahrenheit',
                                answer: 'A unit of measure for temperature; abbreviated °F.',
                            },
                            {
                                question: `CO<sub>2</sub>`,
                                answer: 'The molecular formula for the compound carbon dioxide.',
                            },
                            {
                                question: 'Sublimation',
                                answer: 'A change of state from a solid to a gas without first becoming a liquid.',
                            },
                        ],
                    };
                } else if (activity_id == '029') {
                    Activity = {
                        id: '029',
                        type: 'storyline',
                        Template: 'TemplateRoom2Lesson1Activity2',
                    };
                } else if (activity_id == '030') {
                    Activity = {
                        id: '030',
                        type: 'storyline',
                        Template: 'TemplateRoom2Lesson1Activity3',
                    };
                } else if (activity_id == '031') {
                    Activity = {
                        id: '031',
                        type: 'storyline',
                        Template: 'TemplateRoom2Lesson1Activity3A',
                    };
                } else if (activity_id == '032') {
                    Activity = {
                        id: '032',
                        type: 'storyline',
                        Template: 'TemplateRoom2Lesson1Activity4',
                        src: '/ThirdParty/Matter/PropertiesOfMatter/Activity4/story.html',
                    };
                } else if (activity_id == '033') {
                    Activity = {
                        id: '033',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 matter-ksl matter-ksl-5a custom-ghost',
                        starting_animation: '2B_DoorAnimation.mp4',
                        ending_animation: '2B_DoorAnimation-1.mp4',
                        starting_background: 'ksl-5a-bg.png',
                        ending_background: 'ksl-5a-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Evaporate',
                                answer: 'A liquid is converted to vapor by heating the liquid.',
                            },
                            {
                                question: 'Condense',
                                answer: 'A gas or vapor is changed to a liquid.',
                            },
                            {
                                question: 'Volume',
                                answer: 'A measure of the amount of space an object takes up.',
                            },
                            {
                                question: 'Texture',
                                answer: 'The physical feel of something — smooth, rough, fuzzy, slimy.',
                            },
                            {
                                question: 'Water Vapor',
                                answer: 'Water in its gaseous state.',
                            },
                            {
                                question: 'Matter',
                                answer: 'Anything that takes up space and has mass.',
                            },
                            {
                                question: 'Mass',
                                answer: 'A measurement of how much matter is in an object.',
                            },
                        ],
                    };
                } else if (activity_id == '034') {
                    Activity = {
                        id: '034',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 matter-ksl matter-ksl-5a custom-ghost',
                        starting_animation: '2B_DoorAnimation.mp4',
                        ending_animation: '2B_DoorAnimation-1.mp4',
                        starting_background: 'ksl-5a-bg.png',
                        ending_background: 'ksl-5a-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Weight',
                                answer: "The measure of the amount of gravity acting on an object's mass.",
                            },
                            {
                                question: 'Investigate',
                                answer: 'Making systematic observations, asking questions, gathering information, analyzing data, summarizing results, drawing conclusions, and/or communicating results.',
                            },
                            {
                                question: 'Observe',
                                answer: 'Information about the natural world gathered through the senses and or scientific instruments.',
                            },
                            {
                                question: 'Solid',
                                answer: 'A fundamental state of matter having a definite shape and a definite volume.',
                            },
                            {
                                question: 'Liquid',
                                answer: 'A fundamental state of matter with a definite volume but no definite shape.',
                            },
                            {
                                question: 'Gas',
                                answer: 'A fundamental state of matter in which molecules do not have a fixed volume or shape.',
                            },
                            {
                                question: 'Melting',
                                answer: 'A process by which a substance changes from a solid to a liquid.',
                            },
                        ],
                    };
                } else if (activity_id == '035') {
                    Activity = {
                        id: '035',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 matter-ksl matter-ksl-5a custom-ghost',
                        starting_animation: '2B_DoorAnimation.mp4',
                        ending_animation: '2B_DoorAnimation-1.mp4',
                        starting_background: 'ksl-5a-bg.png',
                        ending_background: 'ksl-5a-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Freezing',
                                answer: 'The process that causes a substance to change from a liquid to a solid.',
                            },
                            {
                                question: 'Heat Energy',
                                answer: 'The energy an object has because of the movement of its molecules.',
                            },
                            {
                                question: 'Density',
                                answer: 'Measure of how tightly packed the particles are; mass compared to its size or volume.',
                            },
                            {
                                question: 'Temperature',
                                answer: 'A degree of hotness or coldness that can be measured using a thermometer.',
                            },
                            {
                                question: 'Vibration',
                                answer: 'Rapid movements back and forth.',
                            },
                            {
                                question: 'Molecule',
                                answer: 'A particle made up of a group of atoms that are chemically bonded.',
                            },
                            {
                                question: 'Beaker',
                                answer: 'A wide cylindrical glass vessel with measurement increments and a pouring lip.',
                            },
                        ],
                    };
                } else if (activity_id == '036') {
                    Activity = {
                        id: '036',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 matter-ksl matter-ksl-5a custom-ghost',
                        starting_animation: '2B_DoorAnimation.mp4',
                        ending_animation: '2B_DoorAnimation-1.mp4',
                        starting_background: 'ksl-5a-bg.png',
                        ending_background: 'ksl-5a-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Flask',
                                answer: 'An upside down funnel shaped glass bottle used in scientific laboratories.',
                            },
                            {
                                question: 'Boiling Point',
                                answer: 'The temperature at which a substance changes from a liquid to a gas.',
                            },
                            {
                                question: 'Celsius',
                                answer: 'The most common temperature scale in the world; abbreviated as °C.',
                            },
                            {
                                question: 'Fahrenheit',
                                answer: 'A unit of measurement used to measure temperature in the United States; abbreviated °F.',
                            },
                            {
                                question: 'CO<sub>2<sub>',
                                answer: 'The molecular formula for carbon dioxide.',
                            },
                        ],
                    };
                } else if (activity_id == '037') {
                    Activity = {
                        id: '037',
                        type: 'states-of-matter',
                    };
                } else if (activity_id == '038') {
                    Activity = {
                        id: '038',
                        type: 'phases-of-matter',
                    };
                } else if (activity_id == '039') {
                    Activity = {
                        id: '039',
                        type: 'assessment1',
                        class: 'assessment matters-assessment',
                        starting_animation: '',
                        ending_animation: '',
                        background: 'phases-of-matter-assessment-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on the correct answer based on your previous knowledge.',
                                position: 'hint',
                                voice_over: 'U2-L4-A21-S1.mp3',
                            },
                        ],
                        dr_astro_narration: [
                            {
                                text: 'Students, what did you observe?',
                                position: 'beforeActivityStart',
                                voice_over: 'U2-L2-A8-S1.mp3',
                            },
                        ],
                        assets: [
                            {
                                question:
                                    'What happens when you heat up or cool down matter?',
                                answer: 'When you apply heat energy to reach a boiling point or take away heat energy to reach a freezing point, the state of matter changes.',
                                available_options: [
                                    {
                                        text: 'When you apply heat energy to reach a boiling point or take away heat energy to reach a freezing point, the state of matter stays the same.',
                                    },
                                    {
                                        text: 'When you apply heat energy to reach a boiling point or take away heat energy to reach a freezing point, the state of matter changes.',
                                    },
                                    {
                                        text: 'When you apply heat energy to reach a freezing point or take away heat energy to reach a boiling point, the state of matter changes.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What happens to the molecules as you bring the water to a boiling point?',
                                answer: 'The higher the heat the more the particles expand and the faster they vibrate.',
                                available_options: [
                                    {
                                        text: 'The higher the heat the less particles expand.',
                                    },
                                    {
                                        text: 'The higher the heat the more the particles expand and the faster they vibrate.',
                                    },
                                    {
                                        text: 'The higher the heat the slower the particles vibrate.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What happens to the molecules as you bring the water to a freezing point?',
                                answer: 'The colder it becomes the more the particles condense and the slower they vibrate.',
                                available_options: [
                                    {
                                        text: 'None of the options are correct.',
                                    },
                                    {
                                        text: 'The colder it becomes the more the particles expand and the faster they vibrate.',
                                    },
                                    {
                                        text: 'The colder it becomes the more the particles condense and the slower they vibrate.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What happens when the water reaches the boiling point?',
                                answer: 'The water changes to a gas or vapor, it evaporates.',
                                available_options: [
                                    {
                                        text: 'The water changes to a gas or vapor, it evaporates.',
                                    },
                                    { text: 'The water does not change.' },
                                    { text: 'The water changes to a solid.' },
                                ],
                            },
                            {
                                question:
                                    'What happens to the water vapor that leaves the beaker when it hits a surface?',
                                answer: 'It cools and condenses turning back to a liquid.',
                                available_options: [
                                    {
                                        text: 'It cools and condenses turning back to a liquid.',
                                    },
                                    { text: 'It maintains the temperature.' },
                                    { text: 'None of the above.' },
                                ],
                            },
                            {
                                question:
                                    'What happens to the ice when heat energy is applied?',
                                answer: 'It melts and turns to a liquid.',
                                available_options: [
                                    { text: 'It melts and turns to a liquid.' },
                                    { text: 'It melts into a solid.' },
                                    { text: 'It remains the same.' },
                                ],
                            },
                            {
                                question:
                                    'What happened to the clear color as the water transitioned to a vapor?',
                                answer: 'It became a white and cloudy vapor.',
                                available_options: [
                                    { text: 'It did not change.' },
                                    {
                                        text: 'It became a greenish cloud of vapor.',
                                    },
                                    {
                                        text: 'It became a white and cloudy vapor.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What happened to the shape of the solid ice as it heated?',
                                answer: 'It had a fixed shape and as it liquified it took the shape of the container.',
                                available_options: [
                                    {
                                        text: 'It remained in a fixed position.',
                                    },
                                    {
                                        text: 'It had a fixed shape and as it liquified it took the shape of the container.',
                                    },
                                    {
                                        text: 'It never took the shape of the container.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What happened to the shape of the liquid water as it heated?',
                                answer: 'It turned into a gas and expanded in all directions.',
                                available_options: [
                                    {
                                        text: 'It turned into water and overflowed.',
                                    },
                                    {
                                        text: 'It turned into a gas and expanded in all directions.',
                                    },
                                    { text: 'None of the above.' },
                                ],
                            },
                            {
                                question:
                                    'What happened to the mass and the volume of the ice when it changed state to a liquid?',
                                answer: 'The mass remained the same, but the volume decreased.',
                                available_options: [
                                    {
                                        text: 'Both the mass and the volume decreased.',
                                    },
                                    {
                                        text: 'Both the mass and the volume remained the same.',
                                    },
                                    {
                                        text: 'The mass remained the same, but the volume decreased.',
                                    },
                                ],
                            },
                        ],
                    };
                } else if (activity_id == '044') {
                    Activity = {
                        id: '044',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 matter-ksl-12',
                        starting_animation: '2B_DoorAnimation.mp4',
                        ending_animation: '2B_DoorAnimation-1.mp4',
                        starting_background: 'ksl-12-bg.png',
                        ending_background: 'ksl-12-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Mixture',
                                answer: 'A thorough blending of two or more substances; not chemically combined.',
                            },
                            {
                                question: 'Volume',
                                answer: 'The amount of space that a substance or object occupies.',
                            },
                            {
                                question: 'Homogeneous mixture',
                                answer: 'A mixture in which the composition is uniform throughout; the dissolved material is present in the same amount throughout the solution.',
                            },
                            {
                                question: 'Heterogeneous mixture',
                                answer: 'A mixture in which the composition is not uniform throughout the mixture; they do not mix evenly, but instead form two separate layers.',
                            },
                            {
                                question: 'Solvent',
                                answer: 'The substance in which the solute is being dissolved.',
                            },
                            {
                                question: 'Solute',
                                answer: 'The substance that dissolves in the solvent.',
                            },
                            {
                                question: 'Dissolve',
                                answer: 'Particles of one or more substances (the solute) are distributed uniformly throughout another substance (the solvent), so that the mixture is homogeneous.',
                            },
                        ],
                    };
                } else if (activity_id == '049') {
                    Activity = {
                        id: '049',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 matter-ksl-11 custom-ghost',
                        starting_animation: '2B_DoorAnimation.mp4',
                        ending_animation: '2B_DoorAnimation-1.mp4',
                        starting_background: 'experiment-bg.jpg',
                        ending_background: 'experiment-bg.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Chemical Properties',
                                answer: 'Characteristics that can be measured or observed only when matter undergoes a change to become an entirely different kind of matter.',
                            },
                            {
                                question: 'Physical Properties',
                                answer: 'Characteristics of matter that can be changed without changing the matter itself,  characteristics that can be observed or measured without changing the identity of the substance.',
                            },
                            {
                                question: 'Chemical Change',
                                answer: 'A process by which substances are changed into different or new substances with different properties.',
                            },
                            {
                                question: 'Physical Change',
                                answer: 'A change of a substance from one form to another without a change in its chemical properties.',
                            },
                            {
                                question: 'New Substance',
                                answer: 'Formed when two or more elements chemically bond or change.',
                            },
                            {
                                question: 'Catalyst',
                                answer: 'A substance that causes a chemical reaction to happen, changes the rate of the reaction.',
                            },
                            {
                                question: 'Erlenmeyer Flask',
                                answer: 'A type of laboratory container which features a flat bottom, a conical body, and a cylindrical neck.',
                            },
                        ],
                    };
                } else if (activity_id == '050') {
                    Activity = {
                        id: '050',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 matter-ksl-11 custom-ghost',
                        starting_animation: '2B_DoorAnimation.mp4',
                        ending_animation: '2B_DoorAnimation-1.mp4',
                        starting_background: 'experiment-bg.jpg',
                        ending_background: 'experiment-bg.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Variable',
                                answer: 'A factor that can be changed or controlled in a scientific experiment.',
                            },
                            {
                                question: 'Gram',
                                answer: 'A metric unit of mass equal to one thousandth of a kilogram.',
                            },
                            {
                                question: 'Scientific Method',
                                answer: 'Identify the problem, conduct background research, generate a hypothesis, conduct the experiment, analyze the results, draw conclusions.',
                            },
                            {
                                question: 'Systematic Investigation',
                                answer: 'A quest to find the answer to a question using a formal research plan.',
                            },
                        ],
                    };
                } else if (activity_id == '040') {
                    Activity = {
                        id: '040',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 matter-ksl-12',
                        starting_animation: '2B_DoorAnimation.mp4',
                        ending_animation: '2B_DoorAnimation-1.mp4',
                        starting_background: 'ksl-12-bg.png',
                        ending_background: 'ksl-12-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'H<sub>2</sub>O',
                                answer: 'The molecular formula for water.',
                            },
                            {
                                question: 'Beaker',
                                answer: 'A wide cylindrical glass vessel with measurement increments and a pouring lip.',
                            },
                            {
                                question: 'Substance',
                                answer: 'A material that can be an element, a compound, or a mixture.',
                            },
                            {
                                question: 'Volume',
                                answer: 'The amount of space that a substance or object occupies.',
                            },
                            {
                                question: 'Element',
                                answer: 'A unique or pure substance that cannot be broken down into any other substance.',
                            },
                            {
                                question: 'Compound',
                                answer: 'A substance made up of two or more elements that are chemically bonded.',
                            },
                            {
                                question: 'Mixture',
                                answer: 'A thorough blending of two or more substances, not chemically combined.',
                            },
                            {
                                question: 'Solubility',
                                answer: 'A solvent, often H<sub>2</sub>O, has the ability to dissolve another substance called a solute.',
                            },
                        ],
                    };
                } else if (activity_id == '041') {
                    Activity = {
                        id: '041',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 matter-ksl-12',
                        starting_animation: '2B_DoorAnimation.mp4',
                        ending_animation: '2B_DoorAnimation-1.mp4',
                        starting_background: 'ksl-12-bg.png',
                        ending_background: 'ksl-12-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Insoluble',
                                answer: 'Does not have the ability to dissolve in H<sub>2</sub>O.',
                            },
                            {
                                question: 'Homogeneous Mixture',
                                answer: 'A mixture in which the composition is uniform throughout; the dissolved material is present in the same amount throughout the solution.',
                            },
                            {
                                question: 'Heterogeneous Mixture',
                                answer: 'A mixture in which the composition is not uniform throughout the mixture; they do not mix evenly, but instead form two separate layers.',
                            },
                            {
                                question: 'Solvent',
                                answer: 'The substance in which the solute is being dissolved.',
                            },
                            {
                                question: 'Solute',
                                answer: 'The substance that dissolves in the solvent.',
                            },
                            {
                                question: 'Dissolve',
                                answer: 'Particles of one or more substances (the solute) are distributed uniformly throughout another substance (the solvent), so that the mixture is homogeneous.',
                            },
                            {
                                question: 'Support',
                                answer: 'To accept that the result matches the hypothesis or prediction in a scientific investigation.',
                            },
                            {
                                question: 'Reject',
                                answer: 'To refuse to accept, consider, submit to, or take for some purpose.',
                            },
                        ],
                    };
                } else if (activity_id == '045') {
                    Activity = {
                        id: '045',
                        type: 'MatterSeparatingMixture',
                    };
                } else if (activity_id == '042') {
                    Activity = {
                        id: '042',
                        type: 'MatterSystematicInvestigation',
                    };
                } else if (activity_id == '043') {
                    Activity = {
                        id: '043',
                        type: 'MatterSortingMixture',
                    };
                } else if (activity_id == '046') {
                    Activity = {
                        id: '046',
                        type: 'MatterM&MSort',
                    };
                } else if (activity_id == '047') {
                    Activity = {
                        id: '047',
                        type: 'ExploringWithMagnet',
                    };
                } else if (activity_id == '051') {
                    Activity = {
                        id: '051',
                        Template: 'TemplateRoom2Lesson4Activity17B',
                        type: 'TemplateRoom2Lesson4Activity17B',
                    };
                } else if (activity_id == '052') {
                    Activity = {
                        id: '052',
                        Template: 'TemplateRoom2Lesson4Activity18',
                        type: 'TemplateRoom2Lesson4Activity18',
                    };
                } else if (activity_id == '053') {
                    Activity = {
                        id: '053',
                        Template: 'TemplateRoom2Lesson4Activity19',
                        type: 'TemplateRoom2Lesson4Activity19',
                    };
                } else if (activity_id == '054') {
                    Activity = {
                        id: '054',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 matter-ksl-11 custom-ghost',
                        starting_animation: '2B_DoorAnimation.mp4',
                        ending_animation: '2B_DoorAnimation-1.mp4',
                        starting_background: 'experiment-bg.jpg',
                        ending_background: 'experiment-bg.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Chemical Change',
                                answer: 'A process by which substances are changed into different or new substances with different properties.',
                            },
                            {
                                question: 'Physical Change',
                                answer: 'A change of a substance from one form to another without a change in its chemical properties.',
                            },
                            {
                                question: 'Scientific Method',
                                answer: 'Identify the problem, conduct background research, generate a hypothesis, conduct the experiment, analyze the results, draw conclusions.',
                            },
                            {
                                question: 'Systematic Investigation',
                                answer: 'A quest to find the answer to a question using a formal research plan.',
                            },
                            {
                                question: 'Variable',
                                answer: 'A factor that can be changed or controlled in a scientific experiment.',
                            },
                        ],
                    };
                } else if (activity_id == '048') {
                    Activity = {
                        id: '048',
                        type: 'assessment1',
                        class: 'assessment matter-assessment',
                        starting_animation: '',
                        ending_animation: '',
                        background: 'exploration-with-magnet-bg.jpg',
                        narration_2b: [
                            {
                                text: 'Click on the correct answer based on your previous knowledge.',
                                position: 'hint',
                                voice_over: 'U2-L4-A21-S1.mp3',
                            },
                        ],
                        dr_astro_narration: [{ text: '', position: '' }],
                        assets: [
                            {
                                question:
                                    'How does matter change when it interacts with other matter?',
                                answer: 'It forms a mixture, it dissolves or it chemically reacts',
                                available_options: [
                                    { text: 'It dissolves only' },
                                    { text: 'It chemically reacts only' },
                                    {
                                        text: 'It forms a mixture, it dissolves or it chemically reacts',
                                    },
                                ],
                            },
                            {
                                question: 'What is a mixture?',
                                answer: 'A combination of two or more substances that do not chemically react with each other',
                                available_options: [
                                    {
                                        text: 'A combination of two or more substances that do not chemically react with each other',
                                    },
                                    {
                                        text: 'A combination of only two substances that do not chemically react with each other',
                                    },
                                    {
                                        text: 'A combination of two or more substances that chemically react with each other',
                                    },
                                ],
                            },
                            {
                                question: 'How can you identify mixtures?',
                                answer: 'You can use zoom goggles to magnify them and exam the consistency in each section of the mixture',
                                available_options: [
                                    { text: 'With magnet' },
                                    { text: 'By boiling' },
                                    {
                                        text: 'You can use zoom goggles to magnify them and exam the consistency in each section of the mixture',
                                    },
                                ],
                            },
                            {
                                question: 'How can you separate mixtures?',
                                answer: 'By boiling and evaporation, by hand or with a magnet',
                                available_options: [
                                    { text: 'By boiling only' },
                                    {
                                        text: 'By boiling and evaporation, by hand or with a magnet',
                                    },
                                    { text: 'With magnet only' },
                                ],
                            },
                            {
                                question: 'What does it mean to dissolve?',
                                answer: 'One substance, the solute melts into the other substance the solvent',
                                available_options: [
                                    {
                                        text: 'One substance, the solvent melts into the other substance the solute',
                                    },
                                    {
                                        text: 'One substance, the solute melts into the other substance the solvent',
                                    },
                                    { text: 'Boiling multiple substances' },
                                ],
                            },
                            {
                                question:
                                    'How do you change the rate of dissolving?',
                                answer: 'Apply heat energy and stir',
                                available_options: [
                                    { text: 'Apply heat energy and stir' },
                                    { text: 'Apply cold energy and stir' },
                                    { text: 'Just by stir' },
                                ],
                            },
                            {
                                question:
                                    'Which substances are soluble and dissolved in the water?',
                                answer: 'Sugar, coffee, vinegar, salt',
                                available_options: [
                                    { text: 'oil, flour' },
                                    { text: 'oil, pepper' },
                                    { text: 'Sugar, coffee, vinegar, salt' },
                                ],
                            },
                        ],
                    };
                } else if (activity_id == '055') {
                    Activity = {
                        id: '055',
                        type: 'assessment1',
                        class: 'assessment matter-assessment matter-changes-assessment',
                        starting_animation: '',
                        ending_animation: '',
                        background: 'green-frame-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on the correct answer based on your previous knowledge.',
                                position: 'hint',
                                voice_over: 'U2-L4-A21-S1.mp3',
                            },
                        ],
                        dr_astro_narration: [{ text: '', position: '' }],
                        assets: [
                            {
                                question:
                                    'How does the chemical reaction change when you apply heat energy and raise the temperature?',
                                answer: 'Heat causes the chemical reaction to speed up.',
                                available_options: [
                                    {
                                        text: 'Heat causes the chemical reaction to slow.',
                                    },
                                    {
                                        text: 'Heat causes the chemical reaction to speed up.',
                                    },
                                    { text: 'A gas was being given off.' },
                                ],
                            },
                            {
                                question:
                                    'When you apply heat energy to the reactants what is the heat energy called?',
                                answer: 'A catalyst.',
                                available_options: [
                                    { text: 'A catalyst.' },
                                    { text: 'A reaction.' },
                                    { text: 'A solution.' },
                                ],
                            },
                            {
                                question:
                                    'What did you observe being given off by the 2 substances as they reacted that resulted in a puffed up or filled up balloon?',
                                answer: 'A gas was being given off.',
                                available_options: [
                                    { text: 'A liquid was being given off.' },
                                    { text: 'A solid was being given off.' },
                                    { text: 'A gas was being given off.' },
                                ],
                            },
                            {
                                question:
                                    'What does it mean if you observe 2 substances reacting and the temperature changes or bubbles form?',
                                answer: 'It means a chemical reaction is occurring.',
                                available_options: [
                                    {
                                        text: 'It means a physical reaction is occurring.',
                                    },
                                    {
                                        text: 'It means a liquid was being given off.',
                                    },
                                    {
                                        text: 'It means a chemical reaction is occurring.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What is created when a chemical reaction causes a chemical change in two substances?',
                                answer: 'A new unique substance is formed.',
                                available_options: [
                                    {
                                        text: 'A new but not unique substance is formed.',
                                    },
                                    {
                                        text: 'A new unique substance is formed.',
                                    },
                                    {
                                        text: 'A new unique substance is not formed.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What happens to a physical change when you apply heat energy and raise the temperature?',
                                answer: 'Heat causes the physical change to speed up.',
                                available_options: [
                                    {
                                        text: 'Heat causes the physical change to speed up.',
                                    },
                                    {
                                        text: 'Heat causes the physical change to slow down.',
                                    },
                                    {
                                        text: 'Heat causes no affect on physical change.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'When you did your scientific investigation with vinegar what steps in the scientific method did you use? #$' +
                                    'Identify the problem, Conduct background research, Generate a hypothesis, ' +
                                    'Conduct a controlled experiment with variables, Record, interpret and analyze data in charts and tables, ' +
                                    'and Draw and Support conclusions',
                                answer: 'All of them except conduct background research.',
                                available_options: [
                                    {
                                        text: 'All of them except conduct background research.',
                                    },
                                    {
                                        text: 'all of them except identify the problem.',
                                    },
                                    { text: 'All of them.' },
                                ],
                            },
                            {
                                question:
                                    'How many of the 6 steps in the scientific method did you use?',
                                answer: '5 steps out of 6.',
                                available_options: [
                                    { text: '5 steps out of 5.' },
                                    { text: '6 steps out of 6.' },
                                    { text: '5 steps out of 6.' },
                                ],
                            },
                            {
                                question:
                                    'When you conducted your experiment in this lesson and drew a conclusion, was it based on your opinion of what would happen, or your observations of what did happen; the evidence or data you collected?',
                                answer: 'What did happen; the evidence, the data collected.',
                                available_options: [
                                    { text: 'Based on your opinion.' },
                                    { text: 'Based on observations.' },
                                    {
                                        text: 'What did happen; the evidence, the data collected.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'As the scientist, creating and carrying out this investigation, do you think science is learned from just observing and forming opinions  or from testing ideas and collecting evidence?',
                                answer: 'From testing ideas and collecting evidence.',
                                available_options: [
                                    { text: 'From testing ideas only.' },
                                    { text: 'From collecting evidence only.' },
                                    {
                                        text: 'From testing ideas and collecting evidence.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'Do you believe if you repeat this same investigation over again, you will always get the same results or you will get a different set of results?',
                                answer: 'You will always get the same results.',
                                available_options: [
                                    {
                                        text: 'You will always get the same results.',
                                    },
                                    {
                                        text: 'You will always get the different results.',
                                    },
                                    {
                                        text: 'You will always get the unexpected results.',
                                    },
                                ],
                            },
                        ],
                    };
                } else if (activity_id == '056') {
                    Activity = {
                        id: '056',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 forces-ksl-1 forces-custom-ghost',
                        starting_animation: 'forces-in.mp4',
                        ending_animation: 'forces-out.mp4',
                        starting_background: 'forces-ksl-bg.png',
                        ending_background: 'forces-ksl-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Acceleration',
                                answer: 'An increase in the rate or speed of an object',
                            },
                            {
                                question: 'Action',
                                answer: 'The process of doing something; movement',
                            },
                            {
                                question: 'Balanced Forces',
                                answer: 'A push or a pull equal in size but opposite in direction',
                            },
                            {
                                question: 'Behavior',
                                answer: 'An organism’s action, reaction, or activity that occurs in response to stimuli',
                            },
                            {
                                question: 'Force',
                                answer: 'A push or a pull that one object exerts on another object with or without direct contact',
                            },
                            {
                                question: 'Inference',
                                answer: 'Deducing or concluding an idea or an explanation based on evidence that is not directly observed',
                            },
                        ],
                    };
                } else if (activity_id == '057') {
                    Activity = {
                        id: '057',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 forces-ksl-1 forces-custom-ghost',
                        starting_animation: 'forces-in.mp4',
                        ending_animation: 'forces-out.mp4',
                        starting_background: 'forces-ksl-bg.png',
                        ending_background: 'forces-ksl-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Proportional',
                                answer: 'A comparison of two or more objects or values that correspond in size or amount to something else',
                            },
                            {
                                question: 'Reaction',
                                answer: 'An action taken in response to a stimuli',
                            },
                            {
                                question: 'Unbalanced Forces',
                                answer: 'Pushes or pulls that are unequal in size and may or may not be opposite in direction',
                            },
                            {
                                question: 'External Force',
                                answer: 'A push or a pull coming from the outside acting on an object',
                            },
                            {
                                question: 'Push',
                                answer: 'A force that moves an object away from something',
                            },
                            {
                                question: 'Pull',
                                answer: 'A force that brings an object closer toward something',
                            },
                        ],
                    };
                } else if (activity_id == '058') {
                    Activity = {
                        id: '058',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 forces-ksl-1 forces-custom-ghost',
                        starting_animation: 'forces-in.mp4',
                        ending_animation: 'forces-out.mp4',
                        starting_background: 'forces-ksl-bg.png',
                        ending_background: 'forces-ksl-bg.png',
                        second_background: 'forces-ksl-bg-2.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        scientific_laws: [
                            {
                                heading: 'Famous In Science:',
                                text: '<b><u>Sir Isac Newton</u></b> was born December 25, 1642 in England. He was a physicist and mathematician during the 17th century. He explored force and created 3 laws to describe the motion of an object.',
                            },
                            {
                                heading: 'Famous In Science:',
                                text: '<b><u>His First law</u></b> was a stationary body will stay stationary unless an external force is applied to it. If there is not a force interfering withh the object, the object will remain stationary or continue to move in a uniform motion in a straight line, until that state is changed by an external force.',
                            },
                            {
                                heading: 'Famous In Science:',
                                text: '<b><u>Second law Force</u></b> is equal to mass times acceleration, and a change im motion (i.e., change in speed) is proportional to the force applied to it.',
                            },
                            {
                                heading: 'Famous In Science:',
                                text: "<b><u>Third Law</u></b> For every action, there is an equal and opposite reaction. </br> </br> Let's see if you can apply any of these laws to your explorations...",
                            },
                        ],
                        assets: [
                            {
                                question: 'Random',
                                answer: 'Done or chosen without any method in particular',
                            },
                            {
                                question: 'Motion',
                                answer: 'A change in position, speed, or distance relative to the original point of reference',
                            },
                            {
                                question: 'Stationary',
                                answer: 'Not moving, fixed, or at rest',
                            },
                            {
                                question: 'Scientific Law',
                                answer: 'A statement, based on repeated experiments or observations, that describes or predicts a range of natural phenomena',
                            },
                            {
                                question: 'Universal',
                                answer: 'Including or covering all or a whole collectively or distributively without limit or exception',
                            },
                            {
                                question: 'Uniform',
                                answer: 'Remaining the same at all times',
                            },
                        ],
                    };
                } else if (activity_id == '060') {
                    Activity = {
                        id: '060',
                        type: 'SoccerAnalysis',
                    };
                } else if (activity_id == '061') {
                    Activity = {
                        id: '061',
                        Template: 'TemplateRoom3Lesson1Activity4',
                        type: 'TemplateRoom3Lesson1Activity4',
                    };
                } else if (activity_id == '062') {
                    Activity = {
                        id: '062',
                        type: 'TugOfWar',
                    };
                } else if (activity_id == '063') {
                    Activity = {
                        id: '063',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 forces-ksl-1 forces-custom-ghost',
                        starting_animation: 'forces-in.mp4',
                        ending_animation: 'forces-out.mp4',
                        starting_background: 'forces-ksl-bg.png',
                        ending_background: 'forces-ksl-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Balanced Forces',
                                answer: 'A push or a pull equal in size but opposite in direction',
                            },
                            {
                                question: 'Behavior',
                                answer: 'An organism’s action, reaction, or activity that occurs in response to stimuli',
                            },
                            {
                                question: 'Force',
                                answer: 'A push or a pull that one object exerts on another object with or without direct contact',
                            },
                            {
                                question: 'Inference',
                                answer: 'Deducing or concluding an idea or an explanation based on evidence that is not directly observed',
                            },
                            {
                                question: 'Unbalanced Forces',
                                answer: 'Pushes or pulls that are unequal in size and may or may not be opposite in direction',
                            },
                            {
                                question: 'Push',
                                answer: 'A force that moves an object away from something',
                            },
                            {
                                question: 'Pull',
                                answer: 'A force that brings an object closer toward something',
                            },
                        ],
                    };
                } else if (activity_id == '060') {
                    Activity = {
                        id: '060',
                        type: 'SoccerAnalysis',
                    };
                } else if (activity_id == '064') {
                    Activity = {
                        id: '064',
                        type: 'ForcesCustomAssessment',
                    };
                } else if (activity_id == '065') {
                    Activity = {
                        id: '065',
                        type: 'key-science-lingo-right',
                        // class:
                        //     'key-science-lingo-1 forces-ksl-2 forces-custom-chalkboard',
                        class: 'key-science-lingo-1 forces-ksl-1 forces-custom-ghost',
                        starting_animation: 'forces-in.mp4',
                        ending_animation: 'forces-out.mp4',

                        starting_background: 'green-board-forces-bg.png',
                        ending_background: 'green-board-forces-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Direction',
                                answer: 'The line or course on which something moves.',
                            },
                            {
                                question: 'Speed',
                                answer: 'The distance traveled by an object in a given amount of time.',
                            },
                            {
                                question: 'Gram',
                                answer: 'A metric unit of mass equal to one thousandth of a kilogram.',
                            },
                            {
                                question: 'Mass',
                                answer: 'A measurement of how much matter is in an object.',
                            },
                            {
                                question: 'Kinetic Energy',
                                answer: 'The energy an object has due to its motion.',
                            },
                            {
                                question: 'Momentum',
                                answer: 'The measurement of mass when in motion.',
                            },
                            {
                                question: 'Opinion',
                                answer: 'What you think of something without the need of facts or knowledge.',
                            },
                        ],
                    };
                } else if (activity_id == '066') {
                    Activity = {
                        id: '066',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 forces-ksl-1 forces-custom-ghost',
                        starting_animation: 'forces-in.mp4',
                        ending_animation: 'forces-out.mp4',
                        starting_background: 'green-board-forces-bg.png',
                        ending_background: 'green-board-forces-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Variable',
                                answer: 'An event, condition, or factor that can be changed or controlled in order to study or test a hypothesis in a scientific experiment.',
                            },
                            {
                                question: 'Weight',
                                answer: "The measure of the amount of gravity or gravitational force acting on an object's mass.",
                            },
                            {
                                question: 'Gravity',
                                answer: 'The observed effect of the force of gravitation',
                            },
                            {
                                question: 'Gravitation',
                                answer: 'A force of gravity',
                            },
                            {
                                question: 'Theory',
                                answer: 'An idea or ideas intended to explain something',
                            },
                            {
                                question: 'Verified',
                                answer: 'Something that is demonstrated as accurate or justified',
                            },
                        ],
                    };
                } else if (activity_id == '059') {
                    Activity = {
                        id: '059',
                        Template: 'TemplateRoom3Lesson1Activity2',
                        type: 'TemplateRoom3Lesson1Activity2',
                    };
                } else if (activity_id == '070') {
                    Activity = {
                        id: '070',
                        Template: 'TemplateRoom3Lesson2Activity12',
                        type: 'TemplateRoom3Lesson2Activity12',
                    };
                } else if (activity_id == '067') {
                    Activity = {
                        id: '067',
                        type: 'CityPark',
                    };
                } else if (activity_id == '068') {
                    Activity = {
                        id: '068',
                        type: 'Gravity',
                    };
                } else if (activity_id == '069') {
                    Activity = {
                        id: '069',
                        type: 'BallToss',
                    };
                } else if (activity_id == '073') {
                    Activity = {
                        id: '073',
                        type: 'assessment1',
                        class: 'assessment matter-assessment bottom-bg',
                        starting_animation: '',
                        ending_animation: '',
                        background: 'sky-ground.png',
                        narration_2b: [
                            {
                                text: 'Click on the correct answer.',
                                position: 'hint',
                                voice_over: 'U3-L2-A14-S1.mp3',
                            },
                        ],
                        dr_astro_narration: [{ text: '', position: '' }],
                        assets: [
                            {
                                question:
                                    'Where does an object tend to go when tossed in the air?',
                                answer: 'Down towards the center of the Earth.',
                                available_options: [
                                    { text: 'Towards the left of the Earth.' },
                                    { text: 'Stay stationary.' },
                                    {
                                        text: 'Down towards the center of the Earth.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What force is acting on the falling object?',
                                answer: 'Gravity or gravitational force.',
                                available_options: [
                                    { text: 'Gravity or gravitational force.' },
                                    { text: 'No force at all.' },
                                    { text: 'Friction.' },
                                ],
                            },
                            {
                                question: 'Where does gravity come from?',
                                answer: 'The earth.',
                                available_options: [
                                    { text: 'The sky.' },
                                    { text: 'The earth.' },
                                    { text: 'From the object.' },
                                ],
                            },
                            {
                                question:
                                    'What do you have to do to get an object to move?',
                                answer: 'Apply sufficient force.',
                                available_options: [
                                    { text: 'Apply sufficient force.' },
                                    { text: 'Apply no force.' },
                                    {
                                        text: 'Apply force lesser than the mass of an object.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What variable determines how much force you must apply to an object?',
                                answer: 'The mass of the object. The greater the mass - the greater the amount of gravitational force acting on the object.',
                                available_options: [
                                    {
                                        text: 'The mass of the object. The greater the mass - the lesser the amount of gravitational force acting on the object.',
                                    },
                                    {
                                        text: 'The energy of the object. The greater the energy - the greater the amount of gravitational force acting on the object.',
                                    },
                                    {
                                        text: 'The mass of the object. The greater the mass - the greater the amount of gravitational force acting on the object.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'To win the pinball game what must you have?',
                                answer: 'The same amount of force each time you shoot and the same mass for the ball.',
                                available_options: [
                                    {
                                        text: 'The different amount of force each time you shoot and the same mass for the ball.',
                                    },
                                    {
                                        text: 'The same amount of force each time you shoot and the same mass for the ball.',
                                    },
                                    {
                                        text: 'The same amount of force each time you shoot and the different mass for the ball.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'Why did Sir Issac Newton test or repeat his investigations or observations on gravity many times?',
                                answer: 'Sir Issac Newton wanted to verify that his findings would happen every time or always be the same result.',
                                available_options: [
                                    {
                                        text: 'Sir Issac Newton wanted to verify that his findings would happen every time or always be the same result.',
                                    },
                                    {
                                        text: 'The same amount of force each time you shoot and the same mass for the ball.',
                                    },
                                    { text: 'To test the mass of an object.' },
                                ],
                            },
                            {
                                question:
                                    'Why did Sir Issac Newton replicate his experiments on gravity?',
                                answer: 'He wanted to be sure that the same result occurred every time he tested his theory.',
                                available_options: [
                                    {
                                        text: 'The same amount of force each time you shoot and the same mass for the ball.',
                                    },
                                    {
                                        text: 'He wanted to be sure that the same result occurred every time he tested his theory.',
                                    },
                                    { text: 'To test the mass of an object.' },
                                ],
                            },
                        ],
                    };
                }
                // Energy -> starting from 074
                else if (activity_id == '074') {
                    Activity = {
                        id: '074',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 forces-ksl-1 forces-custom-ghost',
                        starting_animation: 'energy-in.mp4',
                        ending_animation: 'energy-out.mp4',
                        starting_background: 'energy-ksl-bg.png',
                        ending_background: 'energy-ksl-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Absorb',
                                answer: 'To take up and store energy without reflecting or transmitting that energy.',
                            },
                            {
                                question: 'Radiant Energy',
                                answer: 'Energy that travels in waves and can travel through space.',
                            },
                            {
                                question: 'Behavior',
                                answer: 'A plant or animal action, reaction, or activity that occurs in response to stimuli (e.g., gravity, light, temperature).',
                            },
                            {
                                question: 'Mechanical Energy',
                                answer: 'A type of energy an object has due to its motion or position.',
                            },
                            {
                                question: 'Speed',
                                answer: 'The distance traveled by an object in a given amount of time.',
                            },
                            {
                                question: 'Food Chain',
                                answer: 'The path of energy in the form of food from one organism to another.',
                            },
                            {
                                question: 'Herbivore',
                                answer: 'An animal that obtains nutrients only from plants.',
                            },
                            {
                                question: 'Carnivore',
                                answer: 'An animal that obtains nutrients from eating other animals.',
                            },
                        ],
                    };
                } else if (activity_id == '076') {
                    Activity = {
                        id: '076',
                        type: 'templateRoom4Lesson1Activity6',
                    };
                } else if (activity_id == '077') {
                    Activity = {
                        id: '077',
                        type: 'assessment1',
                        class: 'assessment matter-assessment energy-assessment',
                        starting_animation: '',
                        ending_animation: '',
                        background: 'energy-assessment-bg.jpg',
                        narration_2b: [
                            {
                                text: 'Click on the correct answer.',
                                position: 'hint',
                                voice_over: 'U3-L2-A14-S1.mp3',
                            },
                        ],
                        dr_astro_narration: [{ text: '', position: '' }],
                        assets: [
                            {
                                question: 'What is energy?',
                                answer: 'Energy is the ability to do work or more simply the ability to cause some kind of change.',
                                available_options: [
                                    {
                                        text: 'A chemical process called cellular respiration to transform food into usable chemical energy',
                                    },
                                    {
                                        text: 'Energy is the ability to do work or more simply the ability to cause some kind of change.',
                                    },
                                    {
                                        text: 'Movement or vibration of tiny particles called atoms or molecules in solids, liquids and gases',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What different basic forms can it take?',
                                answer: 'Chemical, mechanical, heat or thermal, light or solar, electrical and sound.',
                                available_options: [
                                    {
                                        text: 'Noise, friction, photosynthesis, cellular respiration',
                                    },
                                    { text: 'Animal, plant solar, and water' },
                                    {
                                        text: 'Chemical, mechanical, heat or thermal, light or solar, electrical and sound.',
                                    },
                                ],
                            },
                            {
                                question: 'How does energy travel?',
                                answer: 'Energy travels in waves or rays.',
                                available_options: [
                                    {
                                        text: 'Energy travels in waves or rays.',
                                    },
                                    { text: 'Energy travels in pairs.' },
                                    {
                                        text: 'Energy travels in through bolts.',
                                    },
                                ],
                            },
                            {
                                question: 'What is chemical energy?',
                                answer: 'Energy stored in chemicals, like atoms, compounds and molecules. This energy is released when a chemical reaction takes place. Like burning wood in a fire.',
                                available_options: [
                                    {
                                        text: 'Energy stored in chemicals, like atoms, compounds and molecules. This energy is released when a chemical reaction takes place. Like burning wood in a fire.',
                                    },
                                    {
                                        text: 'Energy is given off by speeding up the movement or vibration of tiny particles called atoms, or molecules in solids, liquid and geses. The faster the speed the hotter the substance gets.',
                                    },
                                    {
                                        text: 'Energy produced when an object vibrates causing waves of pressure that travel through a medium, like air, water, wood or metal.',
                                    },
                                ],
                            },
                            {
                                question: 'What is Light energy?',
                                answer: 'Energy in the form of radiation given off by chemical reactions and travels in a straight path.',
                                available_options: [
                                    {
                                        text: 'Energy is given off by speeding up the movement or vibration of tiny particles called atoms, or molecules in solids, liquid and geses. The faster the speed the hotter the substance gets.',
                                    },
                                    {
                                        text: 'Energy produced when an object vibrates causing waves of pressure that travel through a medium, like air, water, wood or metal.',
                                    },
                                    {
                                        text: 'Energy in the form of radiation given off by chemical reactions and travels in a straight path.',
                                    },
                                ],
                            },
                            {
                                question: 'What is heat energy?',
                                answer: 'Energy given off by speeding up  the movement or vibration of tiny particles called atoms, or molecules in solids, liquids and gases. The faster the speed  the hotter the substance gets.',
                                available_options: [
                                    {
                                        text: 'Energy produced when an object vibrates causing waves of pressure that travel through a medium, like air, water, wood, or metal.',
                                    },
                                    {
                                        text: 'Energy given off by speeding up  the movement or vibration of tiny particles called atoms, or molecules in solids, liquids and gases. The faster the speed  the hotter the substance gets.',
                                    },
                                    {
                                        text: 'Energy stored in chemicals, like atoms, compounds and molecules. This energy is released when a chemical reaction takes place. Like burning wood in a fire.',
                                    },
                                ],
                            },
                            {
                                question: 'What is sound energy?',
                                answer: 'Energy produced when an object vibrates causing waves of pressure that travel through a medium, like  air, water, wood or metal.',
                                available_options: [
                                    {
                                        text: 'Energy given off by speeding up  the movement or vibration of tiny particles called atoms, or molecules in solids, liquids and gases. The faster the speed  the hotter the substance gets.',
                                    },
                                    {
                                        text: 'Energy in the form of radiation given off by chemical reactions and travels in a straight path.',
                                    },
                                    {
                                        text: 'Energy produced when an object vibrates causing waves of pressure that travel through a medium, like  air, water, wood or metal.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What is the original source of most of the energy on earth?',
                                answer: 'The majority of energy on earth originates from the sun.',
                                available_options: [
                                    {
                                        text: 'The majority of energy on earth originates from the electricity.',
                                    },
                                    {
                                        text: 'The majority of energy on earth originates from the moon.',
                                    },
                                    {
                                        text: 'The majority of energy on earth originates from the sun.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'How does the energy from the sun transition or transfer into all of the different forms of energy?',
                                answer: 'Energy is absorbed and every time you use energy it changes form or transforms into another type of energy.',
                                available_options: [
                                    {
                                        text: 'Energy is absorbed and every time you use energy it changes form or transforms into another type of energy.',
                                    },
                                    {
                                        text: 'Energy is taken and every time you use energy it dissipates.',
                                    },
                                    {
                                        text: 'Energy is taken and every time you use energy it changes stay the same',
                                    },
                                ],
                            },
                            {
                                question:
                                    'Where do all animals including people get their energy?',
                                answer: 'Animals and people get their energy from eating food and transforming it to chemical energy.',
                                available_options: [
                                    {
                                        text: 'Animals and people get their energy from generators and electricity.',
                                    },
                                    {
                                        text: 'Animals and people get their energy from eating food and transforming it to chemical energy.',
                                    },
                                    {
                                        text: 'Animals and people get their energy from making sound and music',
                                    },
                                ],
                            },
                            {
                                question:
                                    'How do plants turn sunlight into usable energy?',
                                answer: 'Plants do a chemical process called photosynthesis inside their cells.',
                                available_options: [
                                    {
                                        text: 'Plants do a chemical process called photosynthesis inside their cells.',
                                    },
                                    {
                                        text: 'Plants get their energy from eating food and transforming it to chemical energy.',
                                    },
                                    {
                                        text: 'Plants get their energy from burning coal, making steam and turning the gears.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'How do organisms including people turn their food into usable energy?',
                                answer: 'Organisms do a chemical process called cellular respiration to transform food into usable chemical energy.',
                                available_options: [
                                    {
                                        text: 'Organisms do a chemical process called heat to transfor food into usable chemical energy.',
                                    },
                                    {
                                        text: 'Organisms do a chemical process called photosynthesis to transform food into usable chemical energy.',
                                    },
                                    {
                                        text: 'Organisms do a chemical process called cellular respiration to transform food into usable chemical energy.',
                                    },
                                ],
                            },
                        ],
                    };
                }
                // Activity 9 KSL
                else if (activity_id == '078') {
                    Activity = {
                        id: '078',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 unit-five-activity',
                        starting_animation: 'energy-in.mp4',
                        ending_animation: 'energy-out.mp4',
                        starting_background:
                            'Energy_in_motion_activity_9_bg.jpg',
                        ending_background: 'Energy_in_motion_activity_9_bg.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: 'Match the word to its definition.',
                                position: 'hint-1',
                                voice_over: '',
                            },
                        ],
                        assets: [
                            {
                                question: 'Force',
                                answer: 'A push or pull on an object caused by an interaction with another object either at rest or already in motion.',
                            },
                            {
                                question: 'Friction',
                                answer: 'A force that opposes motion through direct contact.',
                            },
                            {
                                question: 'Mechanical Energy',
                                answer: 'A type of energy an object has due to its motion or position.',
                            },
                            {
                                question: 'Repel',
                                answer: 'To force away or apart.',
                            },
                            {
                                question: 'Variable',
                                answer: 'An event, condition, or factor that can be changed or controlled in order to study or test a hypothesis in a scientific experiment.',
                            },
                        ],
                    };
                }
                // Activity 9A KSL
                else if (activity_id == '079') {
                    Activity = {
                        id: '079',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 unit-five-activity',
                        starting_animation: 'energy-in.mp4',
                        ending_animation: 'energy-out.mp4',
                        starting_background:
                            'Energy_in_motion_activity_9_bg.jpg',
                        ending_background: 'Energy_in_motion_activity_9_bg.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: 'Match the word to its definition.',
                                position: 'hint-1',
                                voice_over: '',
                            },
                        ],
                        assets: [
                            {
                                question: 'Speed',
                                answer: 'The distance traveled by an object in a given amount of time.',
                            },
                            {
                                question: 'Investigation',
                                answer: 'A procedure that is carried out to gather information or data.',
                            },
                            {
                                question: 'Mass',
                                answer: 'A measurement of how much matter is in an object.',
                            },
                            {
                                question: 'Observation',
                                answer: 'Information about the natural world gathered through the senses and/or scientific instruments.',
                            },
                            {
                                question: 'Trials',
                                answer: 'Multiple sets of measurements or observations in a scientific investigation.',
                            },
                            {
                                question: 'Testable',
                                answer: 'A term used to describe a question that can be answered through an experiment or observation.',
                            },
                        ],
                    };
                } else if (activity_id == '080') {
                    Activity = {
                        id: '080',
                        type: 'WhyDoThingsMove',
                    };
                } else if (activity_id == '081') {
                    Activity = {
                        id: '081',
                        type: 'KineticVrsPotentialEnergy',
                    };
                } else if (activity_id == '082') {
                    Activity = {
                        id: '082',
                        type: 'FrictionAndResistance',
                    };
                } else if (activity_id == '083') {
                    Activity = {
                        id: '083',
                        type: 'InvestigationControlledExperiment',
                    };
                } else if (activity_id == '084') {
                    Activity = {
                        id: '084',
                        type: 'TemplateRoom4Lesson2Activity14',
                    };
                } else if (activity_id == '085') {
                    Activity = {
                        id: '085',
                        type: 'CollidingWithTheWall',
                    };
                } else if (activity_id == '086') {
                    Activity = {
                        id: '086',
                        type: 'assessment1',
                        class: 'assessment matter-assessment energy-assessment',
                        starting_animation: '',
                        ending_animation: '',
                        background: 'playing-room-bg.png',
                        narration_2b: [
                            {
                                text: 'Click on the correct answer.',
                                position: 'hint',
                                voice_over: 'U3-L2-A14-S1.mp3',
                            },
                        ],
                        dr_astro_narration: [{ text: '', position: '' }],
                        assets: [
                            {
                                question:
                                    'What factors influenced the speed of the cars?',
                                answer: 'Track elevation, Smoothness of track, Friction, Tire type, Thickness of car',
                                available_options: [
                                    {
                                        text: 'Distance, Brand, Color, Friction',
                                    },
                                    {
                                        text: 'Brand, Distance, Smoothness of track',
                                    },
                                    {
                                        text: 'Track elevation, Smoothness of track, Friction, Tire type, Thickness of car',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What factors do the kids need to take into serious consideration when they design and build their soap box car?',
                                answer: 'Tire smoothness, Force, Car body, Mass of the car, Friction',
                                available_options: [
                                    { text: 'Gravity, Tire smoothness, Force' },
                                    {
                                        text: 'Tire smoothness, Force, Car body, Mass of the car, Friction',
                                    },
                                    {
                                        text: 'Friction, Car body, Gravity, Force',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What factors were controlled in their experiments?',
                                answer: 'Every factor except the one they were testing for was controlled.',
                                available_options: [
                                    {
                                        text: 'Every factor except the one they were testing for was controlled.',
                                    },
                                    {
                                        text: 'Every factor including the one they were testing for was controlled.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'How can they manipulate variables to gain more speed to win the race?',
                                answer: 'They can apply more force, use the greatest mass of car - choose a smoother car body, use the greatest elevation, use a smooth track, use a smooth tire tread and use a thin smooth plastic car.',
                                available_options: [
                                    {
                                        text: 'They can apply less force, use the least mass of car - choose a rougher car body, use the lowest elevation, use a smooth track, use a studded tire tread and use a thin smooth rubber car.',
                                    },
                                    {
                                        text: 'They can apply more force, use the greatest mass of car - choose a smoother car body, use the greatest elevation, use a smooth track, use a smooth tire tread and use a thin smooth plastic car.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What is the difference between these scientific investigations: a controlled experiment and a model, simulation and systematic investigation?',
                                answer: 'A controlled experiment regulates every variable but the one being tested. A systematic investigation.',
                                available_options: [
                                    {
                                        text: 'A controlled experiment regulates the variable, the one being tested.',
                                    },
                                    {
                                        text: 'A controlled experiment regulates every variable but the one being tested. A systematic investigation.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'Why do scientists repeat trials several times?',
                                answer: 'Scientists repeat trials to be sure they get the same results every time.',
                                available_options: [
                                    {
                                        text: 'Scientists repeat trials to be sure they get the same results every time.',
                                    },
                                    {
                                        text: 'Scientists repeat trials to be sure they get different results every time.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'Why did all of your evidence from your explorations and experiments lead you to make the decisions you made about the design variables in your soap box car?',
                                answer: 'All of the experiments provided data on the different variables that influenced the speed of the cars.',
                                available_options: [
                                    {
                                        text: 'All of the experiments provided data on the same variables that influenced the speed of the cars.',
                                    },
                                    {
                                        text: 'All of the experiments provided data on the different variables that influenced the speed of the cars.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What happens to the energy moving the car forward when it hit the wall?',
                                answer: 'Some of energy moving the car forward was absorbed by or transferred to the wall and given off as heat and sound energy, the remaining energy not absorbed or transferred pushed the car backwards.',
                                available_options: [
                                    {
                                        text: 'All of energy moving the car forward was absorbed by or transferred to the wall. The remaining energy stops the car.',
                                    },
                                    {
                                        text: 'Some of energy moving the car forward was absorbed by or transferred to the wall and given off as heat and sound energy, the remaining energy not absorbed or transferred pushed the car backwards.',
                                    },
                                ],
                            },
                        ],
                    };
                } else if (activity_id == '087') {
                    Activity = {
                        id: '087',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 forces-ksl-1 electrical-energy-ksl forces-custom-ghost',
                        starting_animation: 'energy-in.mp4',
                        ending_animation: 'energy-out.mp4',
                        starting_background: 'chalk-board-energy-bg.jpg',
                        ending_background: 'chalk-board-energy-bg.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Insulator',
                                answer: 'A material used to reduce or prevent the transfer of electricity, heat, or sound.',
                            },
                            {
                                question: 'Conduct',
                                answer: 'To transmit heat, sound, or electricity through a medium.',
                            },
                            {
                                question: 'Atom',
                                answer: 'The smallest particle of an element that has the chemical properties of that element.',
                            },
                            {
                                question: 'Electron',
                                answer: 'A very small atomic particle that has a negative charge of electricity and travels around the nucleus of an atom.',
                            },
                            {
                                question: 'Proton',
                                answer: 'A very small atomic particle that has a positive charge of electricity and is located in the nucleus of an atom.',
                            },
                            {
                                question: 'Magnetic Poles',
                                answer: 'Region at each end of a magnet where the external magnetic field is strongest.',
                            },
                        ],
                    };
                } else if (activity_id == '088') {
                    Activity = {
                        id: '088',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 forces-ksl-1 electrical-energy-ksl forces-custom-ghost',
                        starting_animation: 'energy-in.mp4',
                        ending_animation: 'energy-out.mp4',
                        starting_background: 'chalk-board-energy-bg.jpg',
                        ending_background: 'chalk-board-energy-bg.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Circuit',
                                answer: 'A complete path around which electricity can flow.',
                            },
                            {
                                question: 'Series Circuit',
                                answer: 'A type of circuit in which there is only one pathway for electrons to flow.',
                            },
                            {
                                question: 'Parallel Circuit',
                                answer: 'A type of branching circuit where the current has an independent path to take through each piece or fixture.',
                            },
                            {
                                question: 'Open Circuit',
                                answer: 'The path is broken and electric current cannot flow continuously.',
                            },
                            {
                                question: 'Closed Circuit',
                                answer: 'An electric current has a complete path to flow continuously.',
                            },
                            {
                                question: 'Attraction',
                                answer: 'The electric or magnetic force exerted by oppositely charged objects; the gravitational force that pulls objects toward each other.',
                            },
                        ],
                    };
                } else if (activity_id == '089') {
                    Activity = {
                        id: '089',
                        type: 'Electricity',
                    };
                } else if (activity_id == '090') {
                    Activity = {
                        id: '090',
                        type: 'TemplateRoom4Lesson3Activity19',
                    };
                } else if (activity_id == '091') {
                    Activity = {
                        id: '091',
                        type: 'DoYouKnowTheDifference',
                    };
                }
                // Activity 21 of unit 4 Energy in Motion lesson 3
                else if (activity_id == '092') {
                    Activity = {
                        id: '092',
                        type: 'DrAstroMakeADryCellBattery',
                    };
                } else if (activity_id == '075') {
                    Activity = {
                        id: '075',
                        type: 'SimpleModelConstruction',
                    };
                } else if (activity_id == '093') {
                    Activity = {
                        id: '093',
                        type: 'AttractOrRepel',
                    };
                } else if (activity_id == '094') {
                    Activity = {
                        id: '094',
                        type: 'FuelMazes',
                    };
                } else if (activity_id == '107') {
                    // Activity = {
                    //     id: '107',
                    //     type: 'articulate-key-science-lingo-right',
                    //     src: '/ThirdParty/WeatherAndClimate/ClimateZonesAndEnvironment/Activity11/story.html'
                    // };
                    Activity = {
                        id: '107',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 within-board matter-ksl custom-ghost',
                        starting_animation: 'Intro_anim_weather.mp4',
                        ending_animation: 'outro_anim_weather.mp4',
                        starting_background: 'bg_act23_u5.png',
                        ending_background: 'bg_act23_u5.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Polar Zone',
                                answer: 'a climate zone characterized by very little precipitation and extremely cold conditions',
                            },
                            {
                                question: 'Temperate Zone',
                                answer: 'a climate zone located between the tropics and the polar circles generally characterized by moderate temperatures rather than extreme hot or cold temperatures',
                            },
                            {
                                question: 'Tropical Zone',
                                answer: 'a climate zone near the equator characterized by warm temperatures',
                            },
                            {
                                question: 'Hemisphere',
                                answer: 'half of Earth (Northern, Southern, Eastern, Western)',
                            },
                        ],
                    };
                } else if (activity_id == '117') {
                    // Activity = {
                    //     id: '117',
                    //     type: 'articulate-key-science-lingo-right',
                    //     src: '/ThirdParty/LivingOrganisms/HumanBody/KSL/story.html',
                    // };
                    Activity = {
                        id: '117',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 act1-ksl',
                        starting_animation: 'Intro_anim_Livingthings.mp4',
                        ending_animation: 'Outro_anim_Livingthings.mp4',
                        starting_background: 'act1_u6_bg.png',
                        ending_background: 'act1_u6_bg.png',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Sensory Organs',
                                answer: 'the skin, eyes, nose, and ears; responsible for gathering information and sending it to the brain',
                            },
                            {
                                question: 'Skin',
                                answer: 'an organ that protects and covers muscles, bones, and internal organs',
                            },
                            {
                                question: 'Brain',
                                answer: 'an organ that controls body functions, located at the top or head of the organism,  protected by your skull',
                            },
                            {
                                question: 'Circulatory System',
                                answer: 'the heart and blood vessels; transports oxygen and other nutrients to the organs and tissues and removes waste',
                            },
                            {
                                question: 'Skeletal System',
                                answer: 'a support structure of bone; gives the body shape, allows movement, makes blood cells, provides protection for organs, stores minerals',
                            },
                            {
                                question: 'Digestive System',
                                answer: 'gastrointestinal tract (GI Tract); breaks down food into small molecules to absorb into the body',
                            },
                            {
                                question: 'Respiratory system',
                                answer: 'The network of organs and tissues that help you breathe. It includes your airways, lungs and blood vessels.',
                            },
                        ],
                    };

                    // // activity 25 assessment with json data
                    // else if (activity_id == '095') {
                    //     Activity = {
                    //         id: '095',
                    //         type: 'assessment1',
                    //         class: 'assessment ksl-unit4-activity25',
                    //         starting_animation: '',
                    //         ending_animation: '',
                    //         background: 'yellow-assessment-background.png',
                    //         narration_2b: [
                    //             {
                    //                 text: 'Click on the best answer possible.',
                    //                 position: 'hint',
                    //                 voice_over: '2BPU8a.mp3',
                    //             },
                    //         ],
                    //         dr_astro_narration: [
                    //             {
                    //                 text: 'When you create and an electrically charged battery, the electrical energy can be transferred to light or sound energy and can power a small fan turning or a bell ringing.',
                    //                 // position: 'beforeActivityStart',
                    //                 position : 'showAfterSecondQuestion',
                    //                 voice_over: 'U2-L2-A8-S1.mp3',
                    //             },
                    //         ],
                    //         assets: [
                    //             {
                    //                 question:
                    //                     'When you run an electric current through a metallic object what will happen to the object?',
                    //                 answer: 'It will turn into a  magnet with a north pole and a south pole.',
                    //                 available_options: [
                    //                     {
                    //                         text:
                    //                             'It will turn into a  magnet with a north pole and a south pole.',
                    //                     },
                    //                     { text: 'It will not turn into a  magnet with a north pole and a south pole.' },
                    //                     {
                    //                         text:
                    //                             'It will turn into a  magnet with a north pole and a north pole.',
                    //                     },
                    //                 ],
                    //             },
                    //             {
                    //                 question: 'What happens when you place a magnetically charged object near a magnet?',
                    //                 answer:
                    //                     'The magnet will be attracted to or repel the metallically charged object.',
                    //                 available_options: [
                    //                     {
                    //                         text:
                    //                             'The magnet will be attracted to or repel the metallically charged object.',
                    //                     },
                    //                     {
                    //                         text:
                    //                             'The magnet will not be attracted to or repel the metallically charged object.',
                    //                     },
                    //                     { text: 'The magnet will be attracted to or repel the metallically charged objectives.' },
                    //                 ],
                    //             },
                    //             {
                    //                 question: 'An electric circuit will conduct electrical energy in a loop, if the circuit is?',
                    //                 answer: 'Closed or in a complete circle without an opening or switch.',
                    //                 available_options: [
                    //                     {
                    //                         text:
                    //                             'Closed or in a complete circle without an opening or switch.',
                    //                     },
                    //                     {
                    //                         text:
                    //                             'Open or in a complete circle without an opening or switch.',
                    //                     },
                    //                     { text: 'Closed or in a complete circle without an opening and switch.' },
                    //                 ],
                    //             },
                    //             {
                    //                 question:
                    //                     'Materials that conduct electricity are made of?',
                    //                 answer:
                    //                     'Magnetic material and are considered conductors.',
                    //                 available_options: [
                    //                     { text: 'Magnetic material and are considered conductors.' },
                    //                     {
                    //                         text:
                    //                             'Magnetic material and are not considered conductors.',
                    //                     },
                    //                     {
                    //                         text:
                    //                             'Magnetic material and are not considering conductors.',
                    //                     },
                    //                 ],
                    //             },
                    //             {
                    //                 question:
                    //                     'Materials that do not conduct electricity are made of?',
                    //                 answer:
                    //                     'Non Metallic materials and are called insulators.',
                    //                 available_options: [
                    //                     {
                    //                         text:
                    //                             'Metallic materials and are called insulators.',
                    //                     },
                    //                     {
                    //                         text:
                    //                             'Non Metallic materials which are called insulators.',
                    //                     },
                    //                     {
                    //                         text:
                    //                             'Non Metallic materials and are called insulators.',
                    //                     },
                    //                 ],
                    //             },
                    //             {
                    //                 question:
                    //                     'What does an authentic scientific investigation include?',
                    //                 answer: 'An authentic scientific investigation can include several steps of the “the scientific method” but does not have to include all of the steps.',
                    //                 available_options: [
                    //                     {
                    //                         text:
                    //                             'An authentic scientific investigation can include several steps of the “the scientific method” but does not have to include all of the steps.',
                    //                     },
                    //                     {
                    //                         text:
                    //                             'An authentic scientific investigation can include several steps of the “the scientific method” but does not have to include some of the steps.',
                    //                     },
                    //                     {
                    //                         text:
                    //                             'A prediction is a statement that tells what will happen under certain conditions.',
                    //                     },
                    //                 ],
                    //             },
                    //             {
                    //                 question: 'What do you base your end conclusions in the science activities you did in this adventure on?',
                    //                 answer: 'The conclusions that were made were based on the evidence gained from observing and recording the data from each test.',
                    //                 available_options: [
                    //                     {
                    //                         text:
                    //                             'The conclusions that was made were based on the evidence gained from observing and recording the data from each test.',
                    //                     },
                    //                     {
                    //                         text:
                    //                             'The conclusions that were made were based on the evidence gained from observations and recording the data from each test.',
                    //                     },
                    //                     { text: 'The conclusions that were made were based on the evidence gained from observing and recording the data from each test.' },
                    //                 ],
                    //             },
                    //             // end of questions
                    //         ],
                    //     };
                }
                // ksl of unit 5 first five ksl
                else if (activity_id == '096') {
                    // Activity = {
                    //     id: '096',
                    //     type: 'articulate-key-science-lingo-right',
                    //     src: '/ThirdParty/WeatherAndClimate/Water/KSL/story.html',
                    // };
                    Activity = {
                        id: '096',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 unit-five-activity',
                        starting_animation: 'Intro_anim_weather.mp4',
                        ending_animation: 'outro_anim_weather.mp4',
                        starting_background: 'unit_five_activity_one_fore.jpg',
                        ending_background: 'unit_five_activity_one_fore.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Climate',
                                answer: 'The average pattern of weather that occurs in a certain location over many years',
                            },
                            {
                                question: 'Condensation',
                                answer: 'The process by which water is changed from a gas (water vapor) to a liquid; a stage of the water cycle.',
                            },
                            {
                                question: 'Evaporation',
                                answer: 'The process in which a liquid is converted to its vapor phase by heating the liquid; a stage of the water cycle.',
                            },
                            {
                                question: 'Physical Change',
                                answer: 'A change of a substance from one form to another without a change in its chemical properties.',
                            },
                            {
                                question: 'Precipitation',
                                answer: 'A form of water that condenses in the atmosphere and falls to Earth’s surface; a stage of the water cycle.',
                            },
                        ],
                    };
                }
                // activity 1 of unit 5 last 4 ksl
                else if (activity_id == '097') {
                    Activity = {
                        id: '097',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 unit-five-activity',
                        starting_animation: 'Intro_anim_weather.mp4',
                        ending_animation: 'outro_anim_weather.mp4',
                        starting_background: 'unit_five_activity_one_fore.jpg',
                        ending_background: 'unit_five_activity_one_fore.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'State of Matter',
                                answer: 'The phases matter can take: solid, liquid, or gas.',
                            },
                            {
                                question: 'Water Vapor',
                                answer: 'Water in its gaseous state.',
                            },
                            {
                                question: 'Water Cycle',
                                answer: 'The continuous movement of water through the environment by evaporation, condensation, precipitation, transpiration, and runoff.',
                            },
                            {
                                question: 'Weather',
                                answer: 'The condition of the atmosphere at a given time and place.',
                            },
                        ],
                    };
                }
                // Unit 5 activity 2 lesson 2
                else if (activity_id == '098') {
                    // Activity = {
                    //     id: '098',
                    //     type: 'TemplateRoom5Lesson1Activity2',
                    // };
                    Activity = {
                        id: '098',
                        type: 'articulate-key-science-lingo-right',
                        src: '/ThirdParty/WeatherAndClimate/Water/ADropOfWater/story.html',
                    };
                } else if (activity_id == '101') {
                    // Activity = {
                    //     id: '101',
                    //     type: 'TemplateRoom5Lesson1Activity4',
                    // };
                    Activity = {
                        id: '101',
                        type: 'articulate-key-science-lingo-right',
                        src: '/ThirdParty/WeatherAndClimate/Water/ConstructionProject/story.html',
                    };
                } else if (activity_id == '100') {
                    // Activity = {
                    //     id: '100',
                    //     type: 'Activity5ToTheOcean',
                    // };
                    Activity = {
                        id: '100',
                        type: 'articulate-key-science-lingo-right',
                        src: '/ThirdParty/WeatherAndClimate/Water/ToTheOcean/story.html',
                    };
                } else if (activity_id == '099') {
                    /** Weather and Climate 1, actiivty 4 */
                    // Activity = {
                    //     id: '099',
                    //     type: 'WaterTravelers',
                    // };
                    Activity = {
                        id: '099',
                        type: 'articulate-key-science-lingo-right',
                        src: '/ThirdParty/WeatherAndClimate/Water/WaterTravelers/story.html',
                    };
                }
                // unit 5 activity 8 assessment
                else if (activity_id == '103') {
                    Activity = {
                        id: '103',
                        type: 'assessment1',
                        class: 'assessment ksl-unit5-activity8',
                        starting_animation: '',
                        ending_animation: '',
                        background: 'unit-five-activity-eight-background.jpg',
                        narration_2b: [
                            {
                                text: 'Click on the correct answer.',
                                position: 'hint',
                                voice_over: '2BPU8a.mp3',
                            },
                        ],
                        dr_astro_narration: [
                            {
                                text: '',
                                // position: 'beforeActivityStart',
                                position: '',
                                voice_over: '',
                            },
                        ],
                        assets: [
                            {
                                question:
                                    'What is the purpose of the water cycle or hydrologic cycle?',
                                answer: "This cycle provides a way for water to be cleansed and cycled back into the system as clean water. If water didn't naturally recycle itself, we would run out of clean water, which is essential to life.",
                                available_options: [
                                    {
                                        text: 'It is the water released from clounds in any form or state like rain, freezing rain, sleet, snow or hail',
                                    },
                                    {
                                        text: "This cycle provides a way for water to be cleansed and cycled back into the system as clean water. If water didn't naturally recycle itself, we would run out of clean water, which is essential to life.",
                                    },
                                    {
                                        text: 'There is no particular purpose for the water cycle. ',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What is evaporation and what form is the water in before and during this process?',
                                answer: 'It is a  change in the state of water, or matter, from a liquid to water vapor or a gas when exposed to heat or a rise in temperature.',
                                available_options: [
                                    {
                                        text: 'We need the biosphere which is made up of the earth or the geosphere, the hydrospahre or water, and the atmosphere all interecting togather.',
                                    },
                                    {
                                        text: 'It is a  change in the state of water when it is exposed to cold or below freezing water.',
                                    },
                                    {
                                        text: 'It is a  change in the state of water, or matter, from a liquid to water vapor or a gas when exposed to heat or a rise in temperature.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'Where does evaporation happen in the water cycle or hydrologic cycle?',
                                answer: 'This step in the water cycle happens when any body of water like a pond, a lake, a steam, and even a puddle or glass of water is  exposed to a rise in surface temperature.',
                                available_options: [
                                    {
                                        text: 'It is the upstake of water as a liquid at the roots of a plant, the transport of liquid water through the plant tissues, and the release of water as water uapor or a gas by leaves',
                                    },
                                    {
                                        text: 'This step in the water cycle happens when any body of water like a pond, a lake, a steam, and even a puddle or glass of water is  exposed to a rise in surface temperature.',
                                    },
                                    {
                                        text: 'This step in the water cycle happens when any body of water like a pond is exposed to below freezing temperature.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What is condensation and what form is the water in?',
                                answer: 'It is a  change in the state of water, or matter, from water vapor or a gas  to a liquid  when the temperature lowers or gets colder. ',
                                available_options: [
                                    {
                                        text: 'It is a  change in the state of water, or matter, from water vapor or a gas  to a liquid  when the temperature lowers or gets colder. ',
                                    },
                                    {
                                        text: 'This cycle provides a way of water to be cleansed and cycled back into the system as clean water. If water did not natually recycle itself, we would run out of clean water',
                                    },
                                    {
                                        text: 'It is a  change in the state of water, or matter, from water vapor or a gas  to a liquid  when it is heated or gets hotter.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What is precipitation and what form is the water in?',
                                answer: 'It is the water released from clouds in any form or state like  rain, freezing rain, sleet, snow, or hail.',
                                available_options: [
                                    {
                                        text: 'It is the uptake of water as a liquid  at the roots of a plant, the transport of liquid water through the plant tissues',
                                    },
                                    {
                                        text: 'It is a change in the state of water, or matter, from a liquid to water vapor or a gas when exposed to heat or a rise in temperature',
                                    },
                                    {
                                        text: 'It is the water released from clouds in any form or state like  rain, freezing rain, sleet, snow, or hail.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What is transpiration and what form is the water in?',
                                answer: 'It is the uptake of water as a liquid  at the roots of a plant, the transport of liquid water through the plant tissues, and the release of water as water vapor or a gas by leaves.',
                                available_options: [
                                    {
                                        text: 'It is the uptake of water as a liquid  at the roots of a plant, the transport of liquid water through the plant tissues, and the release of water as water vapor or a gas by leaves.',
                                    },
                                    {
                                        text: 'It is the water released from clouds in any form or state like  rain, freezing rain, sleet, snow, or hail.',
                                    },
                                    {
                                        text: 'Water flows through rivers streams, and through ponds and lakes to the ocean.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What is transport  and what form is the water in?',
                                answer: 'It is the movement of water as gas and as a liquid through the atmosphere in the form of clouds, and specifically from over the oceans to over land.',
                                available_options: [
                                    {
                                        text: 'It is the movement of water through the rivers and the lakes ',
                                    },
                                    {
                                        text: 'This step in the water cycle happens when anybody of water like a pond, a lake, a system and even a pupple or glass of water is exposed to a rise in surface temperature',
                                    },
                                    {
                                        text: 'It is the movement of water as gas and as a liquid through the atmosphere in the form of clouds, and specifically from over the oceans to over land.',
                                    },
                                ],
                            },
                            // next start from here
                            {
                                question:
                                    'What causes a water molecule to move from evaporating to condensing  to precipitating?',
                                answer: 'A change in temperature causes a change in the  state of water or matter.',
                                available_options: [
                                    {
                                        text: 'A change in temperature causes a change in the  state of water or matter.',
                                    },
                                    {
                                        text: 'It is the water released from clouds in any form or state like rain, freezing rain, sleet, snow or hail.',
                                    },
                                    {
                                        text: 'A change in environmental conditions causes change in state.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What parts of  Earth’s system of layers do we need to live or support life on earth?',
                                answer: 'We need the biosphere which is made up of: the earth or the geosphere, the hydrosphere or water,  and the atmosphere all interacting together.',
                                available_options: [
                                    {
                                        text: 'We need the biosphere which is made up of: the earth or the geosphere, the hydrosphere or water,  and the atmosphere all interacting together.',
                                    },
                                    {
                                        text: 'It is the movement of water as gas and as a liquid through the atmosphere in the forms of clouds, and specifically from over the ocean to over land.',
                                    },
                                    {
                                        text: 'The stratosphere is only what we need to live on Earth',
                                    },
                                ],
                            },

                            // end of questions
                        ],
                    };
                    // Activity = {
                    //     id: '103',
                    //     type: 'articulate-key-science-lingo-right',
                    //     src: '/ThirdParty/WeatherAndClimate/Water/Assessment/story.html',
                    // };
                }

                // unit 5 activity 10  KSL
                else if (activity_id == '104') {
                    Activity = {
                        id: '104',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 unit-five-activity',
                        starting_animation: 'Intro_anim_weather.mp4',
                        ending_animation: 'outro_anim_weather.mp4',
                        starting_background: 'unit_five_activity_one_fore.jpg',
                        ending_background: 'unit_five_activity_one_fore.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Humidity',
                                answer: 'a measure of the amount of water vapor in the air.',
                            },
                            {
                                question: 'Control Group',
                                answer: ' a group in a scientific experiment that serves as a reference for comparison to the experimental group; a group that is untreated by the factors being tested.',
                            },
                            {
                                question: 'Observation',
                                answer: 'information about the natural world gathered through the senses and/ or scientific instruments.',
                            },
                            {
                                question: 'Experiment',
                                answer: 'a scientific test or procedure that is carried out under controlled conditions to answer a scientific question.',
                            },
                            {
                                question: 'Investigation',
                                answer: 'an organized scientific study of the natural world that may include making systematic observations, asking questions, gathering information, analyzing data, summarizing results, drawing conclusions, and/or communicating results.',
                            },
                            {
                                question: 'Technology',
                                answer: 'the use of scientific knowledge and processes to solve practical problems.',
                            },
                        ],
                    };
                    // Activity = {
                    //     id: '104',
                    //     type: 'articulate-key-science-lingo-right',
                    //     src: '/ThirdParty/WeatherAndClimate/Weather/KSL/story.html',
                    // };
                } else if (activity_id == '105') {
                    // Activity = {
                    //     id: '105',
                    //     type: 'MeteorologyFieldTrip',
                    // };
                    Activity = {
                        id: '105',
                        type: 'articulate-key-science-lingo-right',
                        src: '/ThirdParty/WeatherAndClimate/Weather/MeteorologyFieldTrip/story.html',
                    };
                } else if (activity_id == '106') {
                    // Activity = {
                    //     id: '106',
                    //     type: 'AssesmentWCOne',
                    // };
                    Activity = {
                        id: '096',
                        type: 'articulate-key-science-lingo-right',
                        src: '/ThirdParty/WeatherAndClimate/Weather/Assessment/story.html',
                    };
                } else if (activity_id == '111') {
                    // Activity = {
                    //     id: '111',
                    //     type: 'TemperateZone',
                    // };
                    Activity = {
                        id: '111',
                        type: 'articulate-TemperateZone',
                        src: '/ThirdParty/WeatherAndClimate/ClimateZonesAndEnvironment/Activity15/story.html',
                    };
                } else if (activity_id == '112') {
                    // Activity = {
                    //     id: '112',
                    //     type: 'TropicalZone',
                    // };
                    Activity = {
                        id: '112',
                        type: 'articulate-TropicalZone',
                        src: '/ThirdParty/WeatherAndClimate/ClimateZonesAndEnvironment/Activity16/story.html',
                    };
                } else if (activity_id == '110') {
                    // Activity = {
                    //     id: '110',
                    //     type: 'NothPole',
                    // };
                    Activity = {
                        id: '110',
                        type: 'articulate-NothPole',
                        src: '/ThirdParty/WeatherAndClimate/ClimateZonesAndEnvironment/Activity14/story.html',
                    };
                } else if (activity_id == '102') {
                    // Activity = {
                    //     id: '102',
                    //     type: 'WhereOnEarthDoWeLive',
                    // };
                    Activity = {
                        id: '102',
                        type: 'articulate-key-science-lingo-right',
                        src: '/ThirdParty/WeatherAndClimate/Water/WhereOnEarthDoWeLive/story.html',
                    };
                } else if (activity_id == '108') {
                    Activity = {
                        id: '108',
                        type: 'articulate-LatitudeAndLongitude',
                        src: '/ThirdParty/WeatherAndClimate/ClimateZonesAndEnvironment/Activity12/story.html',
                    };
                } else if (activity_id == '109') {
                    // Activity = {
                    //     id: '109',
                    //     type: 'Earthszones',
                    // };
                    Activity = {
                        id: '109',
                        type: 'articulate-Earthszones',
                        src: '/ThirdParty/WeatherAndClimate/ClimateZonesAndEnvironment/Activity13/story.html',
                    };
                } else if (activity_id == '115') {
                    // Activity = {
                    //     id: '115',
                    //     type: 'Supplykit',
                    // };
                    Activity = {
                        id: '115',
                        type: 'articulate-Supplykit',
                        src: '/ThirdParty/WeatherAndClimate02/PreparednessPlan/Activity02/story.html',
                    };
                } else if (activity_id == '116') {
                    // Activity = {
                    //     id: '116',
                    //     type: 'BuildingAnEmergencyPlan',
                    // };
                    Activity = {
                        id: '116',
                        type: 'articulate-BuildingAnEmergencyPlan',
                        src: '/ThirdParty/WeatherAndClimate02/PreparednessPlan/Activity03/story.html',
                    };
                }
                // activity 113 assessment challange
                else if (activity_id == '113') {
                    // Activity = {
                    //     id: '113',
                    //     type: 'AssessmentChallenge',
                    // };
                    Activity = {
                        id: '113',
                        type: 'articulate-Earthszones',
                        src: '/ThirdParty/WeatherAndClimate/ClimateZonesAndEnvironment/Activity17/story.html',
                    };
                }
                // unit 6 activity 13 KSL first 5 KSL
                else if (activity_id == '123') {
                    // Activity = {
                    //     id: '123',
                    //     type: 'articulate-key-science-lingo-right',
                    //     src: '/ThirdParty/LivingOrganisms/PlantAndAnimalSystems/KSL/story.html',
                    // };
                    Activity = {
                        id: '123',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 unit-five-activity',
                        starting_animation: 'Intro_anim_LivingthingsUnit6.mp4',
                        ending_animation: 'Outro_anim_LivingthingsUnit6.mp4',
                        starting_background: 'unit-six-activity-thirteen.jpg',
                        ending_background: 'beach-night-stars-light.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Invertebrate',
                                answer: 'an animal that lacks a backbone.',
                            },
                            {
                                question: 'Vertebrate',
                                answer: 'an animal that has a backbone.',
                            },
                            {
                                question: 'Food Chain',
                                answer: 'a diagram representing the transfer of energy from the Sun through producers and a series of consumers.',
                            },
                            {
                                question: 'Ecosystem',
                                answer: 'all of the living and nonliving things that interact with each other in an environment.',
                            },
                            {
                                question: 'Producer',
                                answer: 'an organism that produces its own food.',
                            },
                        ],
                    };
                }
                //later
                // unit 6 activity 13 KSL last 4 KSL
                else if (activity_id == '124') {
                    Activity = {
                        id: '124',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 unit-five-activity',
                        starting_animation: 'Intro_anim_LivingthingsUnit6.mp4',
                        ending_animation: 'Outro_anim_LivingthingsUnit6.mp4',
                        starting_background: 'unit-six-activity-thirteen.jpg',
                        ending_background: 'beach-night-stars-light.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: 'Match the word to its definition.',
                                position: 'hint-1',
                                voice_over: '',
                            },
                        ],
                        assets: [
                            {
                                question: 'Consumer',
                                answer: 'an organism that obtains nutrients from producers or other consumers; consumers may be herbivores or carnivores.',
                            },
                            {
                                question: 'Herbivore',
                                answer: 'an animal that obtains nutrients only from plants.',
                            },
                            {
                                question: 'Carnivore',
                                answer: 'an animal that obtains nutrients from eating other animals.',
                            },
                            {
                                question: 'Omnivore',
                                answer: 'an organism that obtains nutrients from both plants and animals.',
                            },
                        ],
                    };
                }
                // unit 6 lesson 1  activity 1 prepare
                else if (activity_id == '114') {
                    // Activity ={
                    //     id:'114',
                    //     type:'ActivityPrepare',
                    // };
                    Activity = {
                        id: '114',
                        type: 'articulate-ActivityPrepare',
                        src: '/ThirdParty/WeatherAndClimate02/PreparednessPlan/Activity01/story.html',
                    };
                }
                // unit 6 lesson 1 activity 15
                else if (activity_id == '125') {
                    Activity = {
                        id: '011',
                        type: 'articulate-key-science-lingo-right',
                        src: '/ThirdParty/LivingOrganisms/PlantAndAnimalSystems/ExploringDifferentSpecies/story.html',
                    };
                    // Activity = {
                    //     id: '125',
                    //     type: 'TemplateRoom6Lesson2Activity15',
                    // };
                } else if (activity_id == '126') {
                    Activity = {
                        id: '011',
                        type: 'articulate-key-science-lingo-right',
                        src: '/ThirdParty/LivingOrganisms/PlantAndAnimalSystems/ComparingOrganelles/story.html',
                    };
                    // Activity = {
                    //     id: '126',
                    //     type: 'ComparingOrganelles',
                    // };
                }
                // Unit 6 Lesson 2 activity 16
                else if (activity_id == '127') {
                    Activity = {
                        id: '011',
                        type: 'articulate-key-science-lingo-right',
                        src: '/ThirdParty/LivingOrganisms/PlantAndAnimalSystems/DecomposerProducerConsumer/story.html',
                    };
                    // Activity = {
                    //     id: '127',
                    //     type: 'TemplateRoom6Lesson2Activity16',
                    // };
                }
                // unit 6 lesson 2 activity 17
                else if (activity_id == '128') {
                    Activity = {
                        id: '011',
                        type: 'articulate-key-science-lingo-right',
                        src: '/ThirdParty/LivingOrganisms/PlantAndAnimalSystems/VertebratesAndInvertebrates/story.html',
                    };
                    // Activity = {
                    //     id: '128',
                    //     type: 'TemplateRoom6Lesson2Activity17',
                    // };
                }

                // unit 7 lesson 2 activity 5
                else if (activity_id == '135') {
                    Activity = {
                        id: '135',
                        type: 'TemplateRoom7Lesson2Actvity5',
                    };
                }
                // unit 7 lesson 2 activity 6 Adaptations Challenge
                else if (activity_id == '136') {
                    Activity = {
                        id: '136',
                        type: 'TemplateRoom7Lesson2Activity6',
                    };
                }

                // unit 6 activity 2
                else if (activity_id == '118') {
                    Activity = {
                        id: '011',
                        type: 'articulate-key-science-lingo-right',
                        src: '/ThirdParty/LivingOrganisms/HumanBody/DigestiveSystem/story.html',
                    };
                    // Activity = {
                    //     id: '118',
                    //     type: 'TemplateRoom6Lesson2Activity2',
                    // };
                }
                // unit6 activity 3
                else if (activity_id == '119') {
                    Activity = {
                        id: '011',
                        type: 'articulate-key-science-lingo-right',
                        src: '/ThirdParty/LivingOrganisms/HumanBody/HeartAndLungs/story.html',
                    };
                    // Activity = {
                    //     id: '119',
                    //     type: 'TemplateRoom6Lesson2Activity3',
                    // };
                } else if (activity_id == '120') {
                    Activity = {
                        id: '011',
                        type: 'articulate-key-science-lingo-right',
                        src: '/ThirdParty/LivingOrganisms/HumanBody/BuildingDifferentOrganSystems/story.html',
                    };
                    // Activity = {
                    //     id: '120',
                    //     type: 'OneOrganSystemAtATime',
                    // };
                } else if (activity_id == '121') {
                    Activity = {
                        id: '011',
                        type: 'articulate-key-science-lingo-right',
                        src: '/ThirdParty/LivingOrganisms/HumanBody/SkeletalModel/story.html',
                    };
                    // Activity = {
                    //     id: '121',
                    //     type: 'SkeletalModel',
                    // };
                }
                // unit 6 activity 8 assessment
                else if (activity_id == '122') {
                    // Activity = {
                    //     id: '122',
                    //     type: 'articulate-key-science-lingo-right',
                    //     src: '/ThirdParty/LivingOrganisms/HumanBody/Assessment/story.html',
                    // };
                    Activity = {
                        id: '122',
                        type: 'assessment1',
                        class: 'assessment ksl-unit5-activity8',
                        starting_animation: '',
                        ending_animation: '',
                        background: 'unit_6_activity_8_bg.jpg',
                        narration_2b: [
                            {
                                text: 'Click on the correct answer.',
                                position: 'hint',
                                voice_over: '2BPU8a.mp3',
                            },
                        ],
                        dr_astro_narration: [
                            {
                                text: '',
                                // position: 'beforeActivityStart',
                                position: '',
                                voice_over: '',
                            },
                        ],
                        assets: [
                            {
                                question: 'What is a system?',
                                answer: 'A collection of parts all working together.',
                                available_options: [
                                    {
                                        text: 'A collection of parts that work against each other. ',
                                    },
                                    {
                                        text: 'A collection of parts all working together.',
                                    },
                                    {
                                        text: 'A group of parts that work together against another group. ',
                                    },
                                ],
                            },
                            {
                                question:
                                    'Why do we call the human body a system?',
                                answer: 'It is a collection of parts called organs working in many subsystems, like the digestive system, all working together.',
                                available_options: [
                                    {
                                        text: 'It is a collection of parts called organs working in many subsystems, like the digestive system, all working together.',
                                    },
                                    {
                                        text: 'Just because someone named it so. ',
                                    },
                                    {
                                        text: 'It is a collection of parts called tissues working in many subsystems',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What subsystems make up the human body?',
                                answer: 'The digestive system, the circulatory system, the respiratory system, the skeletal system, the nervous system and the muscular system. ',
                                available_options: [
                                    {
                                        text: 'Subsystems do not make the human body. ',
                                    },
                                    {
                                        text: 'The nervous system is the only system in the body.',
                                    },
                                    {
                                        text: 'The digestive system, the circulatory system, the respiratory system, the skeletal system, the nervous system and the muscular system. ',
                                    },
                                ],
                            },
                            {
                                question:
                                    'How are the brain, skin and the sensory organs connected?',
                                answer: 'They are all connected by nerves that carry messages to the brain and away from the brain.',
                                available_options: [
                                    {
                                        text: 'They are all connected by nerves that carry messages to the brain and away from the brain.',
                                    },
                                    {
                                        text: 'They are all connected by tissues that carry messages to the brain and away from the brain.',
                                    },
                                    {
                                        text: 'They are all connected by nerves that carry messages to the brain but not away from the brain.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What are the organs that make up the digestive and excretory systems?',
                                answer: 'The esophagus, the stomach, the small and large intestines, the liver, the pancreas, and the rectum.',
                                available_options: [
                                    {
                                        text: 'The heart and the lungs.',
                                    },
                                    {
                                        text: 'The esophagus, the stomach, the small and large intestines, the liver, the pancreas, and the rectum.',
                                    },
                                    {
                                        text: 'The gallbladder and the pancreas.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'Why do humans have 3 layers of skin covering their bodies?',
                                answer: 'They provide protection, heat regulation, sensation, secretion, absorption, control and regeneration.',
                                available_options: [
                                    {
                                        text: 'The 3 layers give warmth.',
                                    },
                                    {
                                        text: 'They provide protection, heat regulation, sensation, secretion, absorption, control and regeneration.',
                                    },
                                    {
                                        text: 'The 3 layers of skin make sure blood circulation is stable.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What is the purpose and function of the brain?',
                                answer: 'It  is the control center of the body.',
                                available_options: [
                                    {
                                        text: 'It controls the body movements.',
                                    },
                                    {
                                        text: 'It  is the control center of the body.',
                                    },
                                    {
                                        text: 'Its purpose is to think.',
                                    },
                                ],
                            },
                            {
                                question: 'What are the sensory organs?',
                                answer: 'They are the nose, the eyes, the ears, and the mouth or tongue.',
                                available_options: [
                                    {
                                        text: 'They are the nose, the eyes, the ears, and the mouth or tongue.',
                                    },
                                    {
                                        text: 'Heart is a sensory organ as it feels things.',
                                    },
                                    {
                                        text: 'Human do not have sensory organs.',
                                    },
                                ],
                            },

                            {
                                question:
                                    'What is the function and purpose of the heart?',
                                answer: 'It pumps blood throughout the entire body to bring oxygen and nutrients to the cells.',
                                available_options: [
                                    {
                                        text: 'It pumps blood throughout the entire body to bring oxygen and nutrients to the cells.',
                                    },
                                    {
                                        text: 'It pumps blood throughout the entire body to bring CO2 and nutrients to the cells.',
                                    },
                                    {
                                        text: 'It pumps blood throughout the entire body.',
                                    },
                                ],
                            },

                            {
                                question: 'What is the purpose of the lungs?',
                                answer: 'They provide oxygen (O2) to the blood and they take away waste like carbon dioxide (CO2).',
                                available_options: [
                                    {
                                        text: 'It helps in cleaning the blood.',
                                    },
                                    {
                                        text: 'They provide oxygen (O2) to the blood and they take away waste like carbon dioxide (CO2).',
                                    },
                                    {
                                        text: 'They provide Carbon Dioxide (CO2) to the blood. ',
                                    },
                                ],
                            },

                            {
                                question:
                                    'What is the purpose of the digestive and excretory systems?',
                                answer: 'This system takes food in through the mouth and carries the molecules of food through all of the organs in the system to provide nutrients to the cells.',
                                available_options: [
                                    {
                                        text: 'They help to keep the body healthy.',
                                    },
                                    {
                                        text: 'They help in pumping oxygen through the body.',
                                    },
                                    {
                                        text: 'This system takes food in through the mouth and carries the molecules of food through all of the organs in the system to provide nutrients to the cells.',
                                    },
                                ],
                            },

                            {
                                question: 'What is the purpose of the stomach?',
                                answer: 'It receives the food chunks from the esophagus and breaks down the food with acids and enzymes.',
                                available_options: [
                                    {
                                        text: 'It receives the food chunks from the esophagus and breaks down the food with acids and enzymes.',
                                    },
                                    {
                                        text: 'The food we eat is stored here.',
                                    },
                                    {
                                        text: 'It receives the food chunks from the small intestine.',
                                    },
                                ],
                            },

                            {
                                question:
                                    'What is the purpose of the pancreas?',
                                answer: 'It is an organ in the digestive system that makes the digestive juices needed to break down food in the small intestine.',
                                available_options: [
                                    {
                                        text: 'It is an organ in the nervous system that takes messages from one organ to another.',
                                    },
                                    {
                                        text: 'It is an organ in the digestive system that makes the digestive juices to build food components.',
                                    },
                                    {
                                        text: 'It is an organ in the digestive system that makes the digestive juices needed to break down food in the small intestine.',
                                    },
                                ],
                            },

                            {
                                question:
                                    'What is the purpose of muscles in the human body?',
                                answer: 'They move substances like food through systems and provide the ability to move, locomotion.',
                                available_options: [
                                    {
                                        text: 'They move substances like food through systems and provide the ability to move, locomotion.',
                                    },
                                    {
                                        text: 'Muscle mass helps to build human body.',
                                    },
                                    {
                                        text: 'They move substances like enzymes through systems and take away locomotion.',
                                    },
                                ],
                            },

                            {
                                question: 'What is the purpose of the kidneys?',
                                answer: 'They filter the blood to remove toxins or waste.',
                                available_options: [
                                    {
                                        text: 'They filter the blood to remove toxins or waste.',
                                    },
                                    {
                                        text: 'Kidneys help the organs to breathe.',
                                    },
                                    {
                                        text: 'Kidneys are responsible for generating urine.',
                                    },
                                ],
                            },

                            {
                                question: 'What is the purpose of the bladder?',
                                answer: 'It stores urine or waste from the kidneys.',
                                available_options: [
                                    {
                                        text: 'Bladder stores different enzymes of the body.',
                                    },
                                    {
                                        text: 'It stores urine or waste from the kidneys.',
                                    },
                                    {
                                        text: 'It stores liquids in the body.',
                                    },
                                ],
                            },

                            {
                                question:
                                    'What is the purpose of the Skeletal System?',
                                answer: 'It provides support and structure to the body,  movement, protection of internal organs, and storage of minerals and fats.',
                                available_options: [
                                    {
                                        text: 'It provides support and structure to the body,  movement, protection of internal organs, and storage of minerals and fats.',
                                    },
                                    {
                                        text: 'It ensures that all organs are working smoothly.',
                                    },
                                    {
                                        text: 'It holds the body up and helps in movement.',
                                    },
                                ],
                            },

                            {
                                question:
                                    'What is the Reproductive System and what is its function?',
                                answer: 'It is the male and female organs that work together to create new individuals.',
                                available_options: [
                                    {
                                        text: 'The lungs and the liver collectively are called the reproductive system. the function is to ensure food breakdown.',
                                    },
                                    {
                                        text: 'Male and female organs working together to create new organs.',
                                    },
                                    {
                                        text: 'It is the male and female organs that work together to create new individuals.',
                                    },
                                ],
                            },
                            // end of questions
                        ],
                    };
                }

                // unit 6 activity 18 assessment
                else if (activity_id == '129') {
                    // Activity = {
                    //     id: '129',
                    //     type: 'articulate-key-science-lingo-right',
                    //     src: '/ThirdParty/LivingOrganisms/PlantAndAnimalSystems/Assessment/story.html',
                    // };
                    Activity = {
                        id: '129',
                        type: 'assessment1',
                        class: 'assessment ksl-unit5-activity8',
                        starting_animation: '',
                        ending_animation: '',
                        background: 'Unit_6_activity_18.jpg',
                        narration_2b: [
                            {
                                text: 'Click on the correct answer.',
                                position: 'hint',
                                voice_over: '2BPU8a.mp3',
                            },
                        ],
                        dr_astro_narration: [
                            {
                                text: '',
                                // position: 'beforeActivityStart',
                                position: '',
                                voice_over: '',
                            },
                        ],
                        assets: [
                            {
                                question:
                                    'What is the word we use to describe an organism with a skeleton?',
                                answer: 'Vertebrate',
                                available_options: [
                                    {
                                        text: 'Invertebrate',
                                    },
                                    {
                                        text: 'Vertebrate',
                                    },
                                    {
                                        text: 'omnivore',
                                    },
                                ],
                            },
                            {
                                question:
                                    'Which of these organisms are a producer?',
                                answer: 'PLant',
                                available_options: [
                                    {
                                        text: 'Worm',
                                    },
                                    {
                                        text: 'Whale',
                                    },
                                    {
                                        text: 'PLant',
                                    },
                                ],
                            },
                            {
                                question: 'What is a herbivore?',
                                answer: 'an animal or predator that preys on or only eats plants',
                                available_options: [
                                    {
                                        text: 'an animal or predator that preys on or only eats plants',
                                    },
                                    {
                                        text: 'An animal or predator that preys on or only eats meat.',
                                    },
                                    {
                                        text: 'an animal or predator that preys on or eats plants and meat both.',
                                    },
                                ],
                            },
                            {
                                question: 'What is photosynthesis?',
                                answer: 'the process by which plants use sunlight, water, and carbon dioxide to create their own food.',
                                available_options: [
                                    {
                                        text: 'the process by which plants breakdown their food components.',
                                    },
                                    {
                                        text: 'the process by which plants use sunlight, water, and carbon dioxide to create their own food.',
                                    },
                                    {
                                        text: 'The process by which plants absorb sunlight.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'Select the organism that uses photosynthesis to create their own food:',
                                answer: 'Flower',
                                available_options: [
                                    {
                                        text: 'Flower',
                                    },
                                    {
                                        text: 'Wolf',
                                    },
                                    {
                                        text: 'Beetle',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What part of the cell is usually referred as "the powerhouse of the cell"',
                                answer: 'Mitochondria',
                                available_options: [
                                    {
                                        text: 'Chloroplast',
                                    },
                                    {
                                        text: 'Chlorophyll',
                                    },
                                    {
                                        text: 'Mitochondria',
                                    },
                                ],
                            },

                            // end of questions
                        ],
                    };
                }

                // Unit 7 Activities
                else if (activity_id == '132') {
                    Activity = {
                        id: '131',
                        type: 'ATripThroughTime',
                    };
                }
                // unit 7 Activity 1 KSL
                else if (activity_id == '130') {
                    Activity = {
                        id: '130',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 unit-seven_activity_1',
                        starting_animation: 'Intro_anim_Forces.mp4',
                        ending_animation: 'Outro_anim_Diversity.mp4',
                        starting_background: 'unit_7_activity_1_ksl.jpg',
                        ending_background: 'unit_7_activity_1_ksl.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Climate',
                                answer: 'the average pattern of weather that occurs in a certain location over many years',
                            },
                            {
                                question: 'Extinct Species',
                                answer: 'a species that no longer exists',
                            },
                            {
                                question: 'Endangered Species',
                                answer: 'a species of an organism whose population is so small that it is in danger of extinction',
                            },
                            {
                                question: 'Vertebrate',
                                answer: 'an animal that has a spine or backbone',
                            },
                        ],
                    };
                }

                // unit 7 activity 1 A KSL
                else if (activity_id == '131') {
                    Activity = {
                        id: '130',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 unit-seven_activity_1',
                        starting_animation: 'Intro_anim_Forces.mp4',
                        ending_animation: 'Outro_anim_Diversity.mp4',
                        starting_background: 'unit_7_activity_1_ksl.jpg',
                        ending_background: 'unit_7_activity_1_ksl.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Fertilization',
                                answer: 'the process by which the female reproductive cell (egg) is united with the male reproductive cell (sperm)',
                            },
                            {
                                question: 'Spore',
                                answer: 'a reproductive body that is produced by fungi and by some plants and microorganisms and consists of a single cell able to produce a new individual',
                            },
                            {
                                question: 'Erosion',
                                answer: 'the process by which rock, soil and other weathered earth materials are moved from one place to another',
                            },
                            {
                                question: 'Weathering',
                                answer: 'the process by which rocks and other surfaces are broken down',
                            },
                        ],
                    };
                }

                // unit 7 activity 3 assessment
                else if (activity_id == '133') {
                    Activity = {
                        id: '133',
                        type: 'assessment1',
                        class: 'assessment unit7-activity3',
                        starting_animation: '',
                        ending_animation: '',
                        background: 'unit_7_activity3_bg.jpg',
                        narration_2b: [
                            {
                                text: 'Click on the correct answer.',
                                position: 'hint',
                                voice_over: '2BPU8a.mp3',
                            },
                        ],
                        dr_astro_narration: [
                            {
                                text: '',
                                position: 'showDrAstroAssessment',
                                voice_over: '',
                            },
                        ],
                        assets: [
                            {
                                question: 'Why do we call the earth dynamic?',
                                answer: 'Everything is constantly changing.',
                                available_options: [
                                    {
                                        text: 'everything remains the same, nothing changes',
                                    },
                                    {
                                        text: 'Everything is constantly changing.',
                                    },
                                    {
                                        text: 'It is all static ',
                                    },
                                ],
                            },
                            {
                                question: 'Where did all of the  dinosaurs go?',
                                answer: 'They became extinct.',
                                available_options: [
                                    {
                                        text: 'They became extinct.',
                                    },
                                    {
                                        text: 'They evolved into other species ',
                                    },
                                    {
                                        text: 'They still exist in some parts of the world ',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What happened to the earliest species of plants and animals from the Geologic Time Scale or Table?',
                                answer: 'They either changed or adapted to survive or they died out and became extinct.',
                                available_options: [
                                    {
                                        text: 'They died ',
                                    },
                                    {
                                        text: 'They evolved but later died ',
                                    },
                                    {
                                        text: 'They either changed or adapted to survive or they died out and became extinct.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'As the Earth evolved from the early Earth to the Cenozoic Era, what physical environmental conditions changed over time? ',
                                answer: 'Water, the atmosphere, temperature, shape of the land, location of the continents',
                                available_options: [
                                    {
                                        text: 'Water, the atmosphere, temperature, shape of the land, location of the continents',
                                    },
                                    {
                                        text: 'Only the temperatures changed ',
                                    },
                                    {
                                        text: 'No environmental conditions changed over time',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What caused the elephant to change shape and size between the Mesozoic Era and the Cenozoic Era?',
                                answer: 'The extinction of the dinosaurs allowed for other species to grow, change and thrive.',
                                available_options: [
                                    {
                                        text: 'Environmental conditions caused the change ',
                                    },
                                    {
                                        text: 'The extinction of the dinosaurs allowed for other species to grow, change and thrive.',
                                    },
                                    {
                                        text: 'They evolved to grow better ',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What caused the alligator or lizard to change shape and size between the Mesozoic Era and the Cenozoic Era?',
                                answer: 'The extinction of the dinosaurs allowed for other species to grow, change and thrive.',
                                available_options: [
                                    {
                                        text: 'The extinction of the dinosaurs allowed for other species to grow, change and thrive.',
                                    },
                                    {
                                        text: 'Environmental conditions caused the change',
                                    },
                                    {
                                        text: 'They evolved to grow better',
                                    },
                                ],
                            },
                            {
                                question:
                                    'Why did the Asian, African forest and Savanna elephants become classified as endangered species?',
                                answer: 'The loss of habitat, hunting them for their tusks, and their biology',
                                available_options: [
                                    {
                                        text: ' The environmental changes resulted in them being endangered',
                                    },
                                    {
                                        text: 'They were preserved to save them from being hunted.  ',
                                    },
                                    {
                                        text: 'The loss of habitat, hunting them for their tusks, and their biology',
                                    },
                                ],
                            },
                            {
                                question:
                                    'To learn about how the Earth has changed and how organisms have evolved over time, what types of investigation methods did we use?',
                                answer: 'Models, simulations and observations',
                                available_options: [
                                    {
                                        text: 'Models, simulations and observations',
                                    },
                                    {
                                        text: 'field studies',
                                    },
                                    {
                                        text: 'a controlled experiment',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What steps in the scientific method did we use in this investigation?',
                                answer: 'Asking questions, making observations, collecting data, drawing conclusions',
                                available_options: [
                                    {
                                        text: 'Asking questions, making observations and developing a hypothesis',
                                    },
                                    {
                                        text: 'doing an experiment to test your hypothesis, collecting data, analyzing data, drawing conclusions, report results',
                                    },
                                    {
                                        text: 'Asking questions, making observations, collecting data, drawing conclusions',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What were the oldest groups of plants called?',
                                answer: 'Ferns',
                                available_options: [
                                    {
                                        text: 'conifers',
                                    },
                                    {
                                        text: 'mosses',
                                    },
                                    {
                                        text: 'Ferns',
                                    },
                                ],
                            },

                            // end of questions
                        ],
                    };
                } else if (activity_id == '134') {
                    Activity = {
                        id: '134',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 diversity-ksl',
                        starting_animation: 'diversity-in.mp4',
                        ending_animation: 'diversity-out.mp4',
                        starting_background: 'diversity-board-bg.jpg',
                        ending_background: 'diversity-board-bg.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: 'Drag the word your cursor towards the correct defination.',
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Environment',
                                answer: 'An area that includes all living organisms and the surrounding physical features (such as air water soil weather landforms).',
                            },
                            {
                                question: 'Ecosystem',
                                answer: 'All of the living and nonliving things that interact with each other in an environment.',
                            },
                            {
                                question: 'Ecology',
                                answer: 'A branch of science concerned with the interrelationship of organisms and their environments.',
                            },
                            {
                                question: 'Abiotic',
                                answer: 'The nonliving things in the environment such as water, soil, and air.',
                            },
                            {
                                question: 'Biotic',
                                answer: 'The living things that are part of an ecosystem.',
                            },
                            {
                                question: 'Adaptation',
                                answer: 'A characteristic of an organism that increases its chance of survival in its environment.',
                            },
                        ],
                    };
                }

                // unit 7 activity 7 assessment
                else if (activity_id == '137') {
                    Activity = {
                        id: '137',
                        type: 'assessment1',
                        class: 'assessment unit_7_activity7_assessment',
                        starting_animation: '',
                        ending_animation: '',
                        background: 'unit_7_activity_7bg.jpg',
                        narration_2b: [
                            {
                                text: 'Click on the correct answer.',
                                position: 'hint',
                                voice_over: '2BPU8a.mp3',
                            },
                        ],
                        dr_astro_narration: [
                            {
                                text: '',
                                // position: 'beforeActivityStart',
                                position: '',
                                voice_over: '',
                            },
                        ],
                        assets: [
                            {
                                question: 'What is an adaptation?',
                                answer: 'It is a special skill which helps plants and animals do everything they need to do to survive.',
                                available_options: [
                                    {
                                        text: 'The process through which plants and animals continue to keep themselves from changing ',
                                    },
                                    {
                                        text: 'The ability to not change with time. ',
                                    },
                                    {
                                        text: 'It is a special skill which helps plants and animals do everything they need to do to survive.',
                                    },
                                ],
                            },
                            {
                                question: 'What is an ecosystem?',
                                answer: 'It is a group of communities of living biotic organisms, together with the nonliving or abiotic components of their environment, interacting together as a system.',
                                available_options: [
                                    {
                                        text: 'It is a group of communities of living biotic organisms, together with the nonliving or abiotic components of their environment, interacting together as a system.',
                                    },
                                    {
                                        text: 'Only Biotic communities living together and interacting with each other',
                                    },
                                    {
                                        text: 'Only Abiotic communities living together and interacting with each other',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What environmental conditions determine the survival of organisms in an ecosystem? ',
                                answer: 'Atmosphere, water, climate, shape of the land,diet, shelter',
                                available_options: [
                                    {
                                        text: 'climate, diet, shelter',
                                    },
                                    {
                                        text: 'Atmosphere, water, climate, shape of the land,diet, shelter',
                                    },
                                    {
                                        text: 'none of the above',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What is an animal or plant adaptation?',
                                answer: 'It is any change in the behavioral, chemical or physical characteristics of an animal that helps it to survive in its environment.',
                                available_options: [
                                    {
                                        text: 'Changes in the physical characteristics of an animal that helps it to survive in its environment.',
                                    },
                                    {
                                        text: 'Changes in chemical characteristics of an animal that helps it to survive in its environment.',
                                    },
                                    {
                                        text: 'It is any change in the behavioral, chemical or physical characteristics of an animal that helps it to survive in its environment.',
                                    },
                                ],
                            },
                            {
                                question: 'What is a physical adaptation?',
                                answer: 'A change in the structure or shape of the  internal or external body of an organism.',
                                available_options: [
                                    {
                                        text: 'When an organism adapts to the physical environment',
                                    },
                                    {
                                        text: 'A change in the structure or shape of the external body of an organism.',
                                    },
                                    {
                                        text: 'A change in the structure or shape of the  internal or external body of an organism.',
                                    },
                                ],
                            },
                            {
                                question: 'What is a behavioral adaptation?',
                                answer: 'A change in the habits, acts,  or movement of an organism.',
                                available_options: [
                                    {
                                        text: 'A change in the habits, acts,  or movement of an organism.',
                                    },
                                    {
                                        text: 'A change in the movement of an organism.',
                                    },
                                    {
                                        text: 'A change in the habits of an organism.',
                                    },
                                ],
                            },
                            {
                                question: 'What is a chemical adaptation?',
                                answer: 'A change or mutation  in the DNA or genes of an organism.',
                                available_options: [
                                    {
                                        text: 'A change in the chemical properties of an organism.',
                                    },
                                    {
                                        text: 'A change in how an organism reacts to external stimuli.',
                                    },
                                    {
                                        text: 'A change or mutation  in the DNA or genes of an organism.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'How have  alligators, polar bears and desert plants survived over time?',
                                answer: 'By adapting its physical characteristics, behaviors and/or  life cycle to suit the changes in the environment.',
                                available_options: [
                                    {
                                        text: 'By adapting its physical characteristics, behaviors and/or  life cycle to suit the changes in the environment.',
                                    },
                                    {
                                        text: 'By not adapting to the environmental conditions',
                                    },
                                    {
                                        text: ' By traveling to other areas on earth',
                                    },
                                ],
                            },

                            // end of questions
                        ],
                    };
                }

                // unit 7 lesson 3 activity 8  first 5 ksl
                else if (activity_id == '138') {
                    Activity = {
                        id: '138',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 unit-seven_activity_1',
                        starting_animation: 'Intro_anim_Forces.mp4',
                        ending_animation: 'Outro_anim_Diversity.mp4',
                        starting_background: 'unit_7_activity_1_ksl.jpg',
                        ending_background: 'unit_7_activity_1_ksl.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: 'Drag the word your cursor towards the correct defination.',
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Ecosystem',
                                answer: 'All of the living and nonliving things that interact with each other in an environment.',
                            },
                            {
                                question: 'Life Cycle',
                                answer: "The stages of an organism's growth and development.",
                            },
                            {
                                question: 'Food Chain',
                                answer: 'A diagram representing the transfer of energy from the Sun through producers and a series of consumers.',
                            },
                            {
                                question: 'Invertebrate',
                                answer: 'An animal that lacks a backbone.',
                            },
                            {
                                question: 'Population',
                                answer: 'All members of the same species living together at the same time in the same area.',
                            },
                        ],
                    };
                }
                // unit 7 lesson 3 activity 8  second 5 ksl
                else if (activity_id == '139') {
                    Activity = {
                        id: '139',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 unit-seven_activity_1',
                        starting_animation: 'Intro_anim_Forces.mp4',
                        ending_animation: 'Outro_anim_Diversity.mp4',
                        starting_background: 'unit_7_activity_1_ksl.jpg',
                        ending_background: 'unit_7_activity_1_ksl.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: 'Drag the word your cursor towards the correct defination.',
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Community',
                                answer: 'Populations of different species of organisms living together in the same geographic area.',
                            },
                            {
                                question: 'Germination',
                                answer: 'The process by which plants begin to grow from a seed or spore.',
                            },
                            {
                                question: 'Pollinate',
                                answer: 'To transfer pollen from the male reproductive structure to the female reproductive structure to fertilize the flowering plant.',
                            },
                            {
                                question: 'Pistil',
                                answer: 'The female reproductive structure of a flowering plant.',
                            },
                            {
                                question: 'Pollen',
                                answer: 'The fine dustlike powder that contains the male reproductive cells of seed-bearing plants.',
                            },
                        ],
                    };
                }

                // unit 7 lesson 3 activity 8A last 5 Ksl
                else if (activity_id == '140') {
                    Activity = {
                        id: '140',
                        type: 'key-science-lingo-right',
                        class: 'key-science-lingo-1 unit-seven_activity_1',
                        starting_animation: 'Intro_anim_Forces.mp4',
                        ending_animation: 'Outro_anim_Diversity.mp4',
                        starting_background: 'unit_7_activity_1_ksl.jpg',
                        ending_background: 'unit_7_activity_1_ksl.jpg',
                        narration_2b: [
                            {
                                text: 'Click on "Start" to begin the activity.',
                                position: 'instruction',
                                voice_over: '2BPU7a.mp3',
                            },
                            {
                                text: "Drag with your cursor the word to it's correct definition.",
                                position: 'hint-1',
                                voice_over: '2BR1L14.mp3',
                            },
                        ],
                        assets: [
                            {
                                question: 'Complete Metamorphosis',
                                answer: 'A type of insect development characterized by the presence of a larval stage with different feeding habits.',
                            },
                            {
                                question: 'Incomplete Metamorphosis',
                                answer: 'A type of insect development characterized by the similar appearance of pre-adults and adults.',
                            },
                            {
                                question: 'Larva',
                                answer: 'An early stage in the life cycle of an organism that will undergo complete metamorphosis.',
                            },
                            {
                                question: 'Nymph',
                                answer: 'A pre-adult insect undergoing incomplete metamorphosis.',
                            },
                            {
                                question: 'Pupa',
                                answer: 'A stage in the life cycle of an insect that occurs between larva and adult.',
                            },
                        ],
                    };
                } else if (activity_id == '141') {
                    Activity = {
                        id: '141',
                        type: 'BuildAFoodChain',
                    };
                } else if (activity_id == '142') {
                    Activity = {
                        id: '142',
                        type: 'LifeCycleJuggle',
                    };
                }
                // unit 7 lesson 3 activity 11 assessment
                else if (activity_id == '143') {
                    Activity = {
                        id: '143',
                        type: 'assessment1',
                        class: 'assessment unit7-activity3',
                        starting_animation: '',
                        ending_animation: '',
                        background: 'Unit7_activity11_bg.jpg',
                        narration_2b: [
                            {
                                text: 'Click on the correct answer.',
                                position: 'hint',
                                voice_over: '2BPU8a.mp3',
                            },
                        ],
                        dr_astro_narration: [
                            {
                                text: '',
                                // position: 'beforeActivityStart',
                                position: '',
                                voice_over: '',
                            },
                        ],
                        assets: [
                            {
                                question:
                                    'In what order do you place the sequence of life from the smallest unit, an organism, to the largest area to inhabit, the Earth?',
                                answer: 'Organism, population, community, ecosystem, biome, biosphere, Earth',
                                available_options: [
                                    {
                                        text: ' Organism, ecosystem, population, community, biome, biosphere, Earth',
                                    },
                                    {
                                        text: 'Organism, population, community, ecosystem, biome, Earth, biosphere',
                                    },
                                    {
                                        text: 'Organism, population, community, ecosystem, biome, biosphere, Earth',
                                    },
                                ],
                            },
                            {
                                question: 'How do you define an ecosystem?',
                                answer: 'All of the living biotic and nonliving abiotic things that interact with each other in an environment',
                                available_options: [
                                    {
                                        text: 'All of the living biotic things that interact with each other in an environment',
                                    },
                                    {
                                        text: 'All of the living biotic and nonliving abiotic things that interact with each other in an environment',
                                    },
                                    {
                                        text: 'All of the nonliving abiotic things that interact with each other in an environment',
                                    },
                                ],
                            },
                            {
                                question: 'What is a food chain?',
                                answer: 'It begins with the energy from the sun and then follows a series of organisms from one step or trophic level to the next where each organism is dependent on the organism before it as a source of food until it reaches the apex predator.',
                                available_options: [
                                    {
                                        text: 'It begins with the energy from the sun and then follows a series of organisms from one step or trophic level to the next where each organism is dependent on the organism before it as a source of food until it reaches the apex predator.',
                                    },
                                    {
                                        text: "It begins with the energy from the sun and then follows a series of organisms from one step or trophic level to the next where each organism is independent and doesn't rely on the next trophic level",
                                    },
                                    {
                                        text: 'It begins with the energy from the sun until it reaches the apex predator.',
                                    },
                                ],
                            },
                            {
                                question: 'What is a  food web?',
                                answer: 'The interactions and interdependence of many food chains or communities working together in an ecosystem.',
                                available_options: [
                                    {
                                        text: 'The interactions between a community in an ecosystem.',
                                    },
                                    {
                                        text: 'The interactions and interdependence between different ecosystems.',
                                    },
                                    {
                                        text: 'The interactions and interdependence of many food chains or communities working together in an ecosystem.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'How do you define interdependence in a food web?',
                                answer: 'Everything is connected and all members rely on one another for food.',
                                available_options: [
                                    {
                                        text: 'Everything is connected and all members rely on one another for food.',
                                    },
                                    {
                                        text: 'Every member is independent and there is no reliance on food.',
                                    },
                                    {
                                        text: 'Everything is connected but no reliance on food.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'What would happen if you removed an organism or a  food chain from the food web?',
                                answer: 'The balance between organisms within the food web would be altered or damaged.',
                                available_options: [
                                    {
                                        text: 'Nothing will change in the food web. ',
                                    },
                                    {
                                        text: 'Food web will be slightly disrupted.',
                                    },
                                    {
                                        text: 'The balance between organisms within the food web would be altered or damaged.',
                                    },
                                ],
                            },
                            {
                                question: 'What is a life cycle?',
                                answer: 'It is a series of different phases or developmental steps that each living organism  goes through as it develops into an adult.',
                                available_options: [
                                    {
                                        text: 'It is a series of different phases or developmental steps that each living organism  goes through as it develops into an adult.',
                                    },
                                    {
                                        text: 'Different phases as an organism goes on to transition into a different trophic level. ',
                                    },
                                    {
                                        text: 'The cycle of life.',
                                    },
                                ],
                            },
                            {
                                question:
                                    'How does a life cycle show plant and animal adaptation?',
                                answer: 'It has evolved and altered or adapted throughout  time in reaction to changes in the physical environment and the needs of the organism to continue to survive.',
                                available_options: [
                                    {
                                        text: 'It has evolved and altered or adapted throughout  time in reaction to changes in the external environment ',
                                    },
                                    {
                                        text: 'It has evolved and altered or adapted throughout  time in reaction to changes in the physical environment and the needs of the organism to continue to survive.',
                                    },
                                    {
                                        text: 'The ability to not evolve and alter with time to any physical changes in environment.',
                                    },
                                ],
                            },

                            // end of questions
                        ],
                    };
                } else if (activity_id == '999') {
                    Activity = {
                        id: '142',
                        type: 'articulate-test',
                        src: '/ThirdParty/earthnspace/Lessons1/DemoActivity/index.html',
                    };
                } else {
                    Activity = {
                        id: '01',
                        type: 'no-activity',
                    };
                }
            }
            // else {
            //     Activity = {
            //         id: '01',
            //         type: 'no-activity',
            //     };
            // }

            commit('setActivity', Activity);
            dispatch('getNextActivity', { Activity_id: activity_id });
            localStorage.removeItem('scoreboard_data');
            state.fetching = false;

            // Testing to get right API
            // const room1 = state.RoomDB.find(r => r.room_id == "13");
            // if (room1) {
            //     console.log(room1)
            //     const lesson = room1.lessons.find(l => l.id == "9");
            //     if (lesson) {
            //         console.log(lesson)
            //         const activity = lesson.activities.find(a => a.activity_code == "013");
            //         if (activity) {
            //             // activity found, do something with it
            //             findedActivity = activity
            //             console.log(findedActivity);

            //         }
            //     }

            // }
            // Activity = {
            //         id: findedActivity.activity_code,
            //         type: 'articulate-' + findedActivity.css_class,
            //         src: "https://devactivitiesfromdashboard.s3.us-east-2.amazonaws.com/activities/room-13/lesson-9/activity-8/story.html"
            // }

            // commit('setActivity', Activity);
            // dispatch('getNextActivity', { Activity_id: activity_id });
            // state.fetching = false;
        },

        /**
         * if position = 'none', it will not display dr astro icon
         * set position of dr astro according to the given position parameter
         */
        typewritterNarration(
            { state, commit, dispatch },
            { string, character, nextNarration, position, voiceOver }
        ) {
            if (state.voiceOver) {
                state.voiceOver.pause();
                state.voiceOver.currentTime = 0;
                state.voiceOver = null;
            } else {
                state.voiceOver = null;
            }
            function playVoiceOver(audio) {
                audio.play();
                // setting voiceOver to null when voiceover gets completed
                audio.addEventListener('ended', () => {
                    audio.currentTime = 0;
                    state.voiceOver = null;
                });
            }
            if (voiceOver) {
                state.voiceOver = new Audio(
                    require(`@/assets/audio/voiceover/${voiceOver}`)
                );
            }
            if (character === 'twoB') {
                if (state.showTwoBNarration == false) {
                    commit('setShowTwoBNarration', true);
                    commit('setNarrationText', string);
                    setTimeout(() => {
                        commit('setShowNarration', true);
                        if (state.voiceOver) {
                            dispatch('fadeDownBackgroundMusic');
                            playVoiceOver(state.voiceOver);
                        }
                        if (nextNarration) {
                            commit('setNarrationErase', true);
                            commit('setNextNarration', nextNarration);
                        }
                    }, 500);
                } else {
                    if (state.voiceOver) {
                        dispatch('fadeDownBackgroundMusic');
                        playVoiceOver(state.voiceOver);
                    }
                    commit('setNarrationText', string);
                }
            } else if (character === 'drastro') {
                if (state.showDrAstroNarration == false) {
                    setTimeout(() => {
                        if (position == 'none') {
                            commit('setShowDrAstro', false);
                        } else {
                            commit('setShowDrAstro', true);
                        }
                        setTimeout(() => {
                            commit('setShowTwoBNarration', false);
                            commit('setShowDrAstroNarration', true);
                            commit('setNarrationText', string);
                            commit('setShowNarration', true);
                            if (state.voiceOver) {
                                dispatch('fadeDownBackgroundMusic');
                                playVoiceOver(state.voiceOver);
                            } else if (voiceOver) {
                                dispatch('fadeDownBackgroundMusic');
                                state.voiceOver = new Audio(
                                    require(`@/assets/audio/voiceover/${voiceOver}`)
                                );
                                playVoiceOver(state.voiceOver);
                            }
                            if (nextNarration) {
                                commit('setNarrationErase', true);
                                commit('setNextNarration', nextNarration);
                            }
                        }, 1500);
                    }, 500);
                } else {
                    if (state.voiceOver) {
                        dispatch('fadeDownBackgroundMusic');
                        playVoiceOver(state.voiceOver);
                    }
                    commit('setNarrationText', string);
                }
            } else if (character === 'twoBvtwo') {
                if (state.showTwoBNarration == false) {
                    commit('setShowTwoBNarrationvertwo', true);
                    commit('setNarrationText', string);
                    setTimeout(() => {
                        commit('setShowNarration', true);
                        if (state.voiceOver) {
                            dispatch('fadeDownBackgroundMusic');
                            playVoiceOver(state.voiceOver);
                        }
                        if (nextNarration) {
                            commit('setNarrationErase', true);
                            commit('setNextNarration', nextNarration);
                        }
                    }, 500);
                } else {
                    if (state.voiceOver) {
                        dispatch('fadeDownBackgroundMusic');
                        playVoiceOver(state.voiceOver);
                    }
                    commit('setNarrationText', string);
                }
            } else if (character === 'blobE') {
                if (state.showBlobENarration == false) {
                    if (state.keepShowing == true) {
                        console.log('');
                    } else {
                        commit('setShowTwoBNarration', false);
                        commit('setShowDrAstroNarration', false);
                        commit('setShowNarration', false);
                        commit('setNarrationText', '');
                    }
                    if (state.voiceOver) {
                        dispatch('fadeDownBackgroundMusic');
                        playVoiceOver(state.voiceOver);
                    }
                    commit('setShowBlobENarration', true);
                    commit('setBlobENarrationText', string);
                } else {
                    if (state.voiceOver) {
                        dispatch('fadeDownBackgroundMusic');
                        playVoiceOver(state.voiceOver);
                    }
                    commit('setBlobENarrationText', string);
                }
            } else if (character === 'randomCharacter') {
                if (state.showDrAstroNarration == false) {
                    commit('setShowTwoBNarration', false);
                    commit('setShowDrAstroNarration', true);
                    commit('setNarrationText', string);
                    setTimeout(() => {
                        if (position == 'none') {
                            commit('setShowDrAstro', false);
                        } else {
                            commit('setShowDrAstro', true);
                        }
                        setTimeout(() => {
                            commit('setShowNarration', true);
                            if (state.voiceOver) {
                                dispatch('fadeDownBackgroundMusic');
                                playVoiceOver(state.voiceOver);
                            }
                            if (nextNarration) {
                                commit('setNarrationErase', true);
                                commit('setNextNarration', nextNarration);
                            }
                        }, 1500);
                    }, 500);
                } else {
                    if (state.voiceOver) {
                        dispatch('fadeDownBackgroundMusic');
                        playVoiceOver(state.voiceOver);
                    }
                    if (position == 'none') {
                        commit('setShowDrAstro', false);
                    } else {
                        commit('setShowDrAstro', true);
                    }
                    commit('setNarrationText', string);
                }
            } else {
                console.log('wrong parameters for narration');
            }
        },

        /** to get data from api calls */

        getRoomsFromAPI({ state, commit }) {
            //20
            return new Promise((resolve, reject) => {
                axios
                    .get(state.serverLiveURL + '/api/rooms/list/13', {
                        headers: state.authHeaders,
                    })
                    .then(function (response) {
                        if (response && response.data.status == 'error') {
                            console.log('session expired');
                            commit('setRoomsList', state.tempRoomsList);
                            commit('setActionInProgress', false);
                            commit(
                                'setResponseMessage',
                                'Session expired. Please login again.'
                            );
                            commit('setResponseClass', 'error');
                            commit('setActionInProgress', false);
                            reject(new Error('Session Expired'));
                        } else {
                            /** handle success */
                            commit('setActionInProgress', false);
                            //.reverse()
                            let roomsList = response.data;
                            commit('setRoomsList', roomsList);
                            resolve();
                        }
                    })
                    .catch(function (error) {
                        console.log('er');
                        /** handle error */
                        commit('setActionInProgress', false);
                        if (
                            error.response.status == 401 ||
                            error.response.status == 500
                        ) {
                            commit('setRoomsList', state.tempRoomsList);
                            localStorage.removeItem('user');
                            router.go({ path: '/sign-in' });
                            commit('setEmptyfield');
                            reject(new Error('Session Expired'));
                        }
                        // else if (error.response.status == 429){
                        //     window.location.reload()
                        // }
                        else {
                            reject(error);
                        }
                    });
            });
        },

        // getActivitiesFunction({ commit, state, dispatch }, { Lesson_id, Room_id }) {

        // },

        getCurrentRoom({ state }, Room_id) {
            if (state.Rooms) {
                state.currentRoom = state.Rooms.find(
                    (e) => e.room_id == Room_id
                );
            } else {
                axios
                    .get(state.serverLiveURL + '/api/rooms/list/20', {
                        headers: state.authHeaders,
                    })
                    .then(function (response) {
                        /** handle success */
                        state.fetching = false;
                        let roomsList = response.data;
                        state.Rooms = roomsList;
                        state.currentRoom = state.Rooms.find(
                            (e) => e.room_id == Room_id
                        );
                    })
                    .catch(function (error) {
                        /** handle error */
                        console.log(error);
                    });
            }
        },

        /** to send the activity progress to the server */
        sendActivityProgress(
            { state },
            { activityID, points, completionTimeInSec }
        ) {
            console.log(
                activityID + ' , ' + points + ' , ' + completionTimeInSec
            );
            var activityProgressData = {
                activity_id: activityID,
                points: points,
                completion_time_in_sec: completionTimeInSec,
            };
            return axios
                .post(
                    state.serverLiveURL + '/api/points/set',
                    activityProgressData,
                    {
                        headers: state.authHeaders,
                    }
                )
                .then(function (response) {
                    /** handle success */
                    console.log('r3: ' + JSON.stringify(response.status));
                })
                .catch(function (error) {
                    /** handle error */
                    console.log('e: ' + error);
                });
        },
        async GetLessonsData({ state, commit }, room) {
            // console.log(room)
            axios
                .get(
                    state.serverLiveURL +
                        '/api/lessons-with-activities/' +
                        room.room_id,
                    {
                        headers: state.authHeaders,
                    }
                )
                .then(function (response) {
                    // handle success
                    // sort by name
                    // console.log(response.data)
                    room.lessons = response.data;
                    // response.data.sort((a, b) => {
                    //     return a.name.localeCompare(b.name);
                    // });
                    state.showActivitiesLoader = true;
                    commit('setLessons', response.data);
                    const promises = [];
                    response.data = response.data.filter((lesson) =>
                        lesson.name.toLowerCase().includes('lesson')
                    );
                    // response.data.forEach((lesson) => {
                    //     // console.log(lesson)
                    //     promises.push(dispatch('getActivitiesByLessonID', ({ Room_id: Room_id, Lesson_id: lesson.id })));
                    // });
                    // console.log(response.data)
                    Promise.all(promises).then(() => {
                        state.showActivitiesLoader = false;
                        state.fetching = false;
                    });
                })
                .catch(function (error) {
                    // handle error
                    var Lessons = [];
                    console.log(error);
                    if (error && error.response.status == 401) {
                        localStorage.removeItem('user');
                        router.push({ path: '/sign-in' });
                        commit('setEmptyfield');
                        state.fetching = false;
                    } else if (state.RoomDB) {
                        Lessons = state.RoomDB.find(
                            (e) => e.room_id == Room_id
                        );
                        commit('setLessons', Lessons);
                        state.fetching = false;
                    }
                });
        },
        async GetRoomsData({ commit, state, dispatch }) {
            axios
                .get(state.serverLiveURL + '/api/rooms/list/13', {
                    headers: state.authHeaders,
                })
                .then(function (response) {
                    console.log('then');
                    if (response && response.data.status == 'error') {
                        console.log('session expired');
                        commit('setRoomsList', state.tempRoomsList);
                        commit('setActionInProgress', false);
                        commit(
                            'setResponseMessage',
                            'Session expired. Please login again.'
                        );
                        commit('setResponseClass', 'error');
                        commit('setActionInProgress', false);
                    } else {
                        console.log('then else');
                        /** handle success */
                        commit('setActionInProgress', false);
                        // let roomsList = response.data.reverse();
                        let roomsList = response.data;
                        commit('setRoomsList', roomsList);
                        // console.log(roomsList)
                        commit('setRoomDB', roomsList);
                        console.log(state.RoomDB);
                        state.RoomDB.map((room, index) => {
                            // console.log(room.room_id)
                            dispatch('GetLessonsData', room);
                        });
                    }
                });
        },
        /** to get the activity's data to be shown at scoreboard */
        async getScoreboardData({ state }, { activityID }) {
            console.log(activityID);
            var activityData = {
                activity_id: activityID,
            };
            return await axios
                .post(state.serverLiveURL + '/api/scorecard', activityData, {
                    headers: state.authHeaders,
                })
                .then(function (response) {
                    /** handle success */
                    console.log('res: ' + JSON.stringify(response.data));
                    return response.data;
                })
                .catch(function (error) {
                    /** handle error */
                    console.log('e: ' + error);
                    return error;
                });
        },

        /** method to check if user status is pause or active */
        async checkUserStatus({ commit, state }) {
            try {
                const response = await axios.get(
                    state.serverLiveURL + '/api/userStatus',
                    {
                        headers: state.authHeaders,
                    }
                );
                if (response.data == 'active') {
                    state.user.status = 'active';
                } else if (response.data == 'paused') {
                    state.user.status = 'paused';
                    commit(
                        'setResponseMessage',
                        'You have been paused by your teacher. Please contact your teacher.'
                    );
                    commit('setResponseClass', 'error');
                    clearInterval(state.userStatusInterval);
                    state.bgMusic.volume = 0;
                    state.volume = 0;
                    state.bgSoundMute = true;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },

    getters: {
        getSettingsState(state) {
            return state.oSettings;
        },
    },
    modules: {},
});
