import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@babel/polyfill';
import 'material-design-icons-iconfont';
import 'material-design-icons';
import VueTyperPlugin from 'vue-typer';
import bus from './services/utils/Event.js';
import VueResponsiveText from "vue-responsive-text";

Vue.config.productionTip = false;
Vue.use(VueTyperPlugin);
Vue.use(VueResponsiveText);

Vue.prototype.$bus = bus;

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
